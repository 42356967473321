<template>
  <div>
    <configContainer title="Response Packet">
      <template v-slot:header>
        <v-spacer></v-spacer>{{language.componentLabel.labelShowTemplate}}:
        <div>
          <v-switch
            hide-details
            v-model="config.t_templates.requestEnabled"
            :label="config.t_templates.name"
            :color="config.t_templates.color"
            :disabled="disabled"
          ></v-switch>
        </div>
      </template>
      <template v-slot:content>
        <step_connectionTemplateBlockInbound
          v-if="localData != null"
          :connectionOption="false"
          :templateOption="localData.options.templateId"
          :templateValue="response.parameters.templateId"
          :localData="localData"
          @value-changed="updateTemplate"
          :addUrlAttr="{
            subType: 'DIAMETER',
            category: 'Protocols',
            stepType: 'DIAMETER',
          }"
          :dialogRef="result.dialogRef"
          :result="result"
        ></step_connectionTemplateBlockInbound>
        <testDiameterRequestAttr
          :stepStoredValue="stepStoredValue"
          :result="result"
          :mergedTemplate="mergedTemplate"
          stepType="DIAMETER"
          :radiusDynamicPars="dynamicPars.IPCAN"
          :config="config"
          :templatesData="templatesData"
          @input="updateRequest"
          :isValid="isValid"
        ></testDiameterRequestAttr>
      </template>
    </configContainer>
  </div>
</template>
<script>
import step_connectionTemplateBlockInbound from "@/components/legacy/step_connectionTemplateBlockInbound.vue";
import testDiameterRequestAttr from "@/components/legacy/testDiameterRequestAttr.vue";
import configContainer from "@/commonComponents/configContainer.vue";

import { initializeDynamicParams } from "@/js/testHelpers.js";
import {
  getConfiguredValue,
  addEditedRadiusDiameterValues,
} from "@/js/testHelpers.js";

import { buildDiameterRequestTemplate } from "@/js/testHelpers.js";
export default {
  props: ["localData", "stepStoredValue", "result", "stepType","isValid"],
  components: {
    configContainer,
    testDiameterRequestAttr,
    step_connectionTemplateBlockInbound,
  },
  data() {
    return {
      config: {
        t_templates: {
          color: "green",
          name: "Packet",
          requestEnabled: true,
          type: "packet",
        },
      },
    };
  },
  created() {
    let evalInfo = initializeDynamicParams(
      this.result.additional.additionalEvalParams
    );

    this.dynamicPars = evalInfo.dynamicPars;
    this.evalAliases = evalInfo.evalAliases;
  },
  computed: {
    disabled() {
      let disabled = false;
      console.log(this.result);
      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },

    response() {
      let response = { parameters: {} };
      if (typeof this.stepStoredValue.response != "undefined") {
        response = this.stepStoredValue.response;
      }
      if (typeof this.stepStoredValue.response.parameters == "undefined") {
        this.$set(response, "parameters", {});
        this.$set(response, "req", {});
      }
      return response;
    },
    templatesData() {
      let templatesData = {};
      if (this.localData != null) {
        templatesData = this.localData.templates;
      }
      return templatesData;
    },
    mergedTemplate() {
      let ipcanStep = "DIAMETER";

      let combinedTemplate = {};
      combinedTemplate = getConfiguredValue(ipcanStep, ipcanStep);

      combinedTemplate.req = this.combinedRequest;

      let mergedTemplate = addEditedRadiusDiameterValues(
        combinedTemplate,
        ipcanStep,
        this.stepStoredValue.response,
        this.stepType
      );
      return mergedTemplate;
    },
    combinedRequest() {
      let ipcanStep = "DIAMETER";
      var template = {};
      var configuredValue = getConfiguredValue(ipcanStep, ipcanStep);
      template = JSON.parse(JSON.stringify(configuredValue));
      if (this.config.t_templates.requestEnabled) {
        template = buildDiameterRequestTemplate(
          template,

          ipcanStep,
          this.templatesData,

          this.config["t_templates"]
        );
      }

      return template.req;
    },
  },
  methods: {
    updateRequest(request) {
      let localStoredStep = { ...this.stepStoredValue };
      console.log("diameter response", request);
      this.$set(localStoredStep.response, "req", request);
      this.$emit("stored-value-changed", localStoredStep);
    },
    // updateTemplate(field, value) {
    //   console.log("update tempate called", value);
    //   let localStoredStep = { ...this.stepStoredValue };
    //   if (localStoredStep == null) {
    //     localStoredStep = {};
    //   }
    //   this.$set(localStoredStep, field, value);
    //   this.$emit("stored-value-changed", localStoredStep);
    // },

    updateTemplate(field, value) {
      console.log("update tempate called", value);
      let localStoredStep = { ...this.stepStoredValue };
      if (localStoredStep == null) {
        localStoredStep = {};
      }

      if (
        typeof localStoredStep.response != "undefined" &&
        typeof localStoredStep.response.parameters != "undefined" &&
        typeof localStoredStep.response.parameters.templateId != "undefined" &&
        localStoredStep.response.parameters.templateId != value
      ) {
        //template id is changed. reset request body
        this.$set(localStoredStep.response, "req", {});
      }
      this.$set(localStoredStep.response.parameters, field, value);
      this.$emit("stored-value-changed", localStoredStep);
    },
  },
};
</script>