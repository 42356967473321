<template>
  <v-card>
    <v-tabs v-model="tab" :background-color="color" :dark="dark">
     <template v-if="typeof tabNames=='object'">
         <v-tab
                 v-for="(item, index) in tabNames"
                 :key="index"
                 :id="tabsPrefix + '_tab_' + item.index"
                 :disabled="item.disabled"
         >{{ item.name }}</v-tab>
     </template>
     <template v-else>
         <v-tab
                 v-for="(item, index) in tabNames"
                 :key="index"
                 :id="tabsPrefix + '_tab_' + item"
         >{{ item }}</v-tab>
     </template>
    </v-tabs>
    <v-tabs-items v-model="currentTab">
      <v-tab-item v-for="(item, index) in tabNames" :key="index">
        <v-card flat :id="tabsPrefix + '_body_' + item.index">
          <v-card-text>
            <v-form

              :ref="'tabFormValidation_' + index"
              v-model="tabFormValidation"
            >
              <slot name="tabsContent" :item="item"></slot>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
export default {
  data() {
    return { tab: 0, tabFormValidation: false, currentTab: 0 };
  },
  watch: {
    tabFormValidation(isValid) {
      //console.log("emitted");
      this.$emit("changeIsValid", isValid);
    },
    isValid(isValid) {
      if (isValid) {
        let oldVal = this.currentTab;

        if (
          typeof this.$refs["tabFormValidation_" + oldVal] != "undefined" &&
          this.$refs["tabFormValidation_" + oldVal][0] != "undefined"
        ) {
          let form = this.$refs["tabFormValidation_" + oldVal][0];
          let valid = form.validate();
          if (!valid) {
            this.$emit("changeIsValid", valid);
          }
          return !valid;
        }
      }
    },
    tab(newVal, oldVal) {
      if (typeof this.$refs["tabFormValidation_" + oldVal] != "undefined") {
        let form = this.$refs["tabFormValidation_" + oldVal][0];
        if (!form.validate()) {
          this.$nextTick(function () {
            this.tab = oldVal;
          });
        } else {
          this.currentTab = newVal;
        }
      }
    },
  },
  computed: {
    color() {
      let bgColor = "primary";
      if(typeof this.normal!='undefined' && this.normal==true){
        bgColor="white";
      }
      return bgColor;
    },
    dark() {
      let dark = true;
      if(typeof this.normal!='undefined' && this.normal==true){
        dark= false;
      }
      return dark;
    }
  },
  props: ["tabNames", "isValid", "tabsPrefix","normal"],
};
</script>