<template>
  <div>
    <configContainer title="Condition">
      <template v-slot:content>
        <evalContentTable
          v-model="localEval"
          type="expect"
          operatorType="incNull"
          :result="result"
          :isValid="isValid"
        ></evalContentTable>
      </template>
    </configContainer>
    <subStepsModule
      :groupedStepDependentInfo="groupedStepDependentInfo"
      :stepStoredValue="localStoredValue"
      :stepID="stepID"
      :result="result"
      @group-value-changed="updateTestValue(...arguments)"
      :isValid="isValid"
    ></subStepsModule>

    <localDataGetter
      :stepStoredValue="parameters"
      :result="result"
      :ipcanStepTemplates="ipcanStepTemplates"
      :stepType="stepType"
      application="DIAMETER"
    >
      <template v-slot:default="data">
        <expectDiameterResponse
          :localData="data.localData"
          :stepStoredValue="stepStoredValue"
          :result="result"
          :stepType="stepType"
          v-on="$listeners"
          :isValid="isValid"
        ></expectDiameterResponse>
      </template>
    </localDataGetter>
  </div>
</template>
<script>
import expectDiameterResponse from "@/components/legacy/expectDiameterResponse.vue";
import subStepsModule from "@/components/legacy/subStepsModule.vue";
import evalContentTable from "@/components/legacy/evalContentTable.vue";
import localDataGetter from "@/components/legacy/localDataGetter.vue";
import configContainer from "@/commonComponents/configContainer.vue";
export default {
  components: {
    subStepsModule,

    evalContentTable,
    localDataGetter,

    expectDiameterResponse,
    configContainer,
  },
  props: [
    "groupedStepDependentInfo",
    "stepStoredValue",
    "result",
    "stepID",
    "stepType",
    "ipcanStepTemplates",
    "isValid"
  ],

  computed: {
    parameters() {
      let parameters = {};
      if (
        typeof this.stepStoredValue.response != "undefined" &&
        typeof this.stepStoredValue.response.parameters != "undefined"
      ) {
        parameters = this.stepStoredValue.response.parameters;
      }
      return parameters;
    },
    localStoredValue: {
      get() {
        return this.stepStoredValue;
      },
      set(newValue) {
        console.log("new value");
        this.$emit("stored-value-changed", newValue);
      },
    },
    localEval: {
      get() {
        let evalValue = {};
        if (
          typeof this.localStoredValue.check != "undefined" &&
          typeof this.localStoredValue.check.eval != "undefined"
        ) {
          evalValue = this.localStoredValue.check.eval;
        }
        return evalValue;
      },
      set(newVal) {
        this.$set(this.localStoredValue.check, "eval", newVal);
        this.$emit("stored-value-changed", this.localStoredValue);
      },
    },
  },
  methods: {
    updateTestValue(substepsValue) {
      let local = JSON.parse(JSON.stringify(substepsValue));
      console.log(local);
      this.$set(this.localStoredValue, "substeps", local);
      this.$emit("stored-value-changed", this.localStoredValue);
    },
  },
};
</script>