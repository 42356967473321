<template>
  <div>
    <v-expansion-panels multiple v-model="panel">
      <draggable
        @start="dragging = true"
        @end="endDrag"
        class="step-list"
        v-model="templateValueArray"
        handle=".handle"
        :group="{
          name: groupValue,
          put: true,
          pull: getDraggablePull(groupValue),
        }"
      >
        <step
          v-for="(step, stepID) in templateValue"
          :key="step.stepIdentifier"
          :stepID="stepID"
          :step="step"
          :result="result"
          :templateValue="templateValue"
          :groupedStepDependentInfo="groupedStepDependentInfo"
          @step-value-changed="updateStep(stepID, ...arguments)"
          @remove-step="removeStep(stepID)"
          @insert-next-step="insertNext(stepID)"
          @copy-step="copyStep(stepID)"
          :isValid="isValid"
          @revalidate="$emit('revalidate')"
          @changeIsValid="$emit('changeIsValid', $event)"
          :forWhat="forWhat"
        ></step>
      </draggable>
    </v-expansion-panels>
    <div class="pt-3"></div>
    <primaryButton
      label="Add Step"
      icon="mdi-plus"
      @click="addStep()"
      type="button"
      color="primary"
      :disabled="disabled || !isValid"
      id="addStepButton"
    ></primaryButton>
    <!--     <v-text-field
              type="input"
              :rules="checkSteps(templateValue)"

      ></v-text-field>-->
  </div>
</template>
<script>
import draggable from "vuedraggable";
import step from "@/components/legacy/step.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";

export default {
  components: {
    step,
    primaryButton,
    draggable,
  },
  data: function () {
    return {
      panel: [],
      stepIncrement: 1,
      objectsArray: {},
      template: {},
      dragging: false,
      old: null,
      // stepsIdentifiers: []
    };
  },
  created() {
    if (
      typeof this.templateValue == "object" &&
      Object.keys(this.templateValue).length == 1
    ) {
      this.panel = [0];
    }
  },
  watch: {
    panel(newVal, old) {
      // console.log(newVal, old, this.old);
      if (!this.arraysEqual(newVal, old)) {
        this.$emit("revalidate");
        if (!this.isValid) {
          //this.old = old;
          let merged = newVal.concat(old);
          this.panel = merged.filter(
            (item, pos) => merged.indexOf(item) === pos
          );
        }
      }
    },
  },
  computed: {
    disabled() {
      let disabled = false;
      //console.log(this.result);
      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },

    templateValueArray: {
      //get and set for re-order the drag and drop items
      get() {
        let templateValueArray = [];
        if (typeof this.templateValue != "undefined") {
          templateValueArray = Object.values(this.templateValue);
        }
        return templateValueArray;
      },
      set(newValue) {
        this.$emit("group-value-changed", this.convertToStepObject(newValue));
      },
    },
  },
  props: [
    // "groupValue",
    "result",
    "groupedStepDependentInfo",
    //"ipcanStepTemplates",
    //"expectSubStep",
    //"indexedTemplates",
    //  "inputArray",
    //"fieldAttributes",
    "templateValue",
    "stepsGroups",
    "groupValue",
    "isValid",
    "forWhat"
  ],
  methods: {
    endDrag() {
      this.dragging = false;
      this.$store.commit("stepResorted");
    },
    arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      // If you don't care about the order of the elements inside
      // the array, you should sort both arrays here.
      // Please note that calling sort on an array will modify that array.
      // you might want to clone your array first.

      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    },
    /*    checkSteps(value){
      console.log(value);
      let rules = [];
      if (value!= "undefined") {
        rules.push(
          v =>
            v <= value.lenght >0 ||  "add steps ");
      }
      console.log(rules);
  //this.isValid=true;
      return rules;
    },*/
    getDraggablePull(groupValue) {
      if (typeof this.stepsGroups != "undefined") {
        let group = Object.values(this.stepsGroups);
        group = group.filter((item) => item !== groupValue);
        return group;
      }
    },
    updateStep(stepID, value) {
      let local = { ...this.templateValue };
      // local[stepID] = value;
      //console.log(local);
      this.$set(local, stepID, value);

      this.$emit("group-value-changed", local);
    },
    convertToStepObject(local) {
      let stepObject = {};
      let caller = this;
      local.forEach((element, index) => {
        caller.$set(stepObject, index + 1, element);
      });
      return stepObject;
      // return local;
    },
    removeStep(stepID) {
      let local = { ...this.templateValue };
      // console.log(this.templateValue);
      this.$delete(local, stepID);
      console.log(local);
      //local = Object.assign({}, local);
      //console.log(local);
      let panel = [];
      this.panel.forEach((element) => {
        if (stepID < element) {
          panel.push(element);
        } else if (stepID < element) {
          panel.push(element - 1);
        }
      });
      this.panel = panel;

      this.$emit(
        "group-value-changed",
        this.convertToStepObject(Object.values(local))
      );
    },
    copyStep(stepID) {
      let local = Object.values(this.templateValue);
      // console.log(this.templateValue);
      // this.$set(local, parseInt(stepID) + 1, this.templateValue[stepID]);
      // let index = parseInt(stepID);
      // while (index < Object.keys(this.templateValue).length) {
      //   this.$set(local, index + 2, this.templateValue[index + 1]);
      //   index++;
      // }
      let copiedStep = JSON.parse(JSON.stringify(this.templateValue[stepID]));
      local.splice(parseInt(stepID), 0, copiedStep);
      this.panel.push(parseInt(stepID));

      this.$emit("group-value-changed", this.convertToStepObject(local));
    },
    insertNext(stepID) {
      // let local = { ...this.templateValue };
      // // console.log(this.templateValue);
      // this.$set(local, parseInt(stepID) + 1, {});
      // let index = parseInt(stepID);
      // while (index < Object.keys(this.templateValue).length) {
      //   this.$set(local, index + 2, this.templateValue[index + 1]);
      //   index++;
      // }

      let local = Object.values(this.templateValue);
      local.splice(parseInt(stepID), 0, {});
      this.panel.push(parseInt(stepID));
      this.$emit("group-value-changed", this.convertToStepObject(local));
    },
    addStep() {
      this.$emit("revalidate");
      // console.log(this.templateValueArray);
      if (this.isValid) {
        let local = { ...this.templateValue };
        let checkArray = [];
        if (typeof this.templateValue != "undefined") {
          checkArray = Object.keys(this.templateValue).map(function (item) {
            return parseInt(item, 10);
          });
        }
        let maxKey = 0;
        if (checkArray.length > 0) {
          maxKey = Math.max(...checkArray);
        }
        //this.$set(local, local.length + 1, {});
        this.$set(local, maxKey + 1, {});
        this.$emit("group-value-changed", local);
        this.panel.push(Object.keys(local).length - 1);
      }
    },
  },
};

//    let indexedTemplates={};
// v-if="typeof this.result.additional.stepDependentInfo[$groupValue]["indexedTemplates"]!='undefined'"
//  v-for="(templateContent, templateIndex) in this.result.additional.stepDependentInfo[$groupValue]["indexedTemplates"] "
// {include file=$templateContent["content"][0]|cat:".tpl" assign=renderedHtml}
// indexedTemplates["{$templateIndex}"]={$renderedHtml};
// {/foreach}
// {/if}

//   let expectSubStep={};
//   v-if="typeof this.result.additional.stepDependentInfo[$groupValue]["expectSubStep"]!='undefined'"
//   expectSubStep=result.additional.stepDependentInfo[$groupValue]["expectSubStep"];
//   {foreach this.result.additional.stepDependentInfo[$groupValue]["expectSubStep"]  key=substepKey item=substepContent}
//   {
//     let indexedTemplates={};
//  v-if="typeof $substepContent["Test"]["indexedTemplates"]!='undefined'"
//    v-for="(templateContent, templateIndex) in $substepContent["Test"]["indexedTemplates"] "
//   {include file=$templateContent["content"][0]|cat:".tpl" assign=renderedHtml}
//   indexedTemplates["{$templateIndex}"]={$renderedHtml};
//   {/foreach}
//   {/if}
//   expectSubStep[{$substepKey}]["Test"]["indexedTemplates"]=indexedTemplates;
//   }
//   {/foreach}
//   {result.additional.stepDependentInfo[$groupValue]["expectSubStep"]=""}
//   {/if}

//enclosure
// let $testCasesGroup = $("#{$testCasesGroupId}{$groupValue}");
// storeTestCaseValues(
//   fieldsObject,
//   $testCasesGroup,
//   $testCasesGroup.data("templateValue")
// );
</script>
<style>
.step-list {
  width: 100%;
}
</style>