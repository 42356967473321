<template>
  <accordionHeader
      v-on.stop="$listeners"
      :result="result"
      :templateValue="templateValue"
      :forWhat="forWhat"
  >
    <div class="stepInfoContainer">
      <span class="stepTypeText subtitle-2" title="Click to expand the step">
        <span class="mr-4">Step {{ stepID }}:</span>
        {{ subTypeShow(stepStoredValue) }}{{ getMappedName(stepType) }}</span>
      <span class="stepApnText" title="Click to expand the step">{{
          truncateStr(stepApnText,150)
        }}</span>
      <span>
        <span class="statusInfo">{{ statusInfo }}</span>
        <span class="statusType">{{ statusType }}</span>
        <span class="apnName mt-1">{{ apnName }}</span>
        <span class="" v-bind:title="titleOpenURL">{{ openURL }}</span>
      </span>
      <span class="ml-2 countryFlag">
        <img v-if="countryFlagSource != ''" :src="countryFlagSource" :title="countryFlagTitle"
             class="countryFlagImage mb-n1"/>
      </span>
      <span class="stepTitle" title="Click to expand the step"></span>
      <span class="stepDurationText">{{ stepDurationText }}</span>
      <span class="conditionInfoContainer">
        <v-icon v-if="
            stepStoredValue.Condition != 'undefined' &&
            stepStoredValue.Condition != '' &&
            stepStoredValue.Condition != null
          " color="green">
          mdi-information
        </v-icon>
      </span>
    </div>
  </accordionHeader>
</template>
<script>
import accordionHeader from '@/components/legacy/accordionHeader.vue';

import {checkDiameterProtocol, getSubType} from '@/js/testHelpers.js';
import {findInArray, ipString, trimString,truncateStr} from '@/js/helper.js';
import testCases from '@/cfg/testCases.json';
import options from '@/cfg/options.json';

export default {
  components: {
    accordionHeader,
  },
  props: [
    'stepType',
    'stepStoredValue',
    'stepID',
    'additionalStepsInfo',
    'ipcanStepTemplates',
    'groupedStepDependentInfo',
    'result',
    'templateValue',
    'localData',
    'forWhat'
  ],

  created() {
    this.renderStepHeader(this.stepStoredValue);
  },
  watch: {
    stepStoredValue(newval) {
      this.renderStepHeader(newval);
    },
    'stepStoredValue.parameters.target': function () {
      this.renderStepHeader(this.stepStoredValue);
    },
    'stepStoredValue.target': function () {
      this.renderStepHeader(this.stepStoredValue);
    },
    'stepStoredValue.target.host': function () {
      this.renderStepHeader(this.stepStoredValue);
    },
    'stepStoredValue.target.type': function () {
      this.renderStepHeader(this.stepStoredValue);
    },
    'stepStoredValue.target.port': function () {
      this.renderStepHeader(this.stepStoredValue);
    },
    'stepStoredValue.parameters.target.ip': function () {
      this.renderStepHeader(this.stepStoredValue);
    },
    'stepStoredValue.parameters.target.type': function () {
      this.renderStepHeader(this.stepStoredValue);
    },
    'stepStoredValue.parameters': function () {
      this.renderStepHeader(this.stepStoredValue);
    },
    'stepStoredValue.parameters.port': function () {
      this.renderStepHeader(this.stepStoredValue);
    },
    'stepStoredValue.parameters.downlink': function () {
      //console.log("reqest changeded");
      this.renderStepHeader(this.stepStoredValue);
    },
    'stepStoredValue.parameters.uplink': function () {
      console.log('reqest changeded');
      this.renderStepHeader(this.stepStoredValue);
    },
    'stepStoredValue.req.0.type': function () {
      //console.log("reqest changeded", newVal);
      this.renderStepHeader(this.stepStoredValue);
    },
    'stepStoredValue.subType': function () {
      this.renderStepHeader(this.stepStoredValue);
    },
    localData: function () {
      this.renderStepHeader(this.stepStoredValue);
    },
    'stepStoredValue.req.0.base': function () {
      // console.log("reqest changeded", newVal);
      this.renderStepHeader(this.stepStoredValue);
    },
    'stepStoredValue.check': function () {
          this.renderStepHeader(this.stepStoredValue);
    },
  },
  data() {
    return {
      stepDurationText: '',
      stepApnText: '',
      countryFlagTitle: '',
      countryFlagSource: '',
      apnName: '',
      statusType: '',
      statusInfo: '',
      //showIcon: false,
      openURL: '',
      titleOpenURL: '',
    };
  },
  computed: {
    radiusAccountingStatusTypesArray: function () {
      let radiusAccountingStatusTypesArray = {};
      //console.log(this.groupValue);
      if (
          typeof this.groupedStepDependentInfo[
              'radiusAccountingStatusTypesArray'
              ] != 'undefined'
      ) {
        radiusAccountingStatusTypesArray =
            this.groupedStepDependentInfo['radiusAccountingStatusTypesArray'];
      }
      return radiusAccountingStatusTypesArray;
    },
  },

  methods: {
    truncateStr: truncateStr,

    /*async getTemplateName(stepStoredValue,packetType,index) {
           const data = await this.getIpcanTemplatesName(stepStoredValue,packetType,index);
           this.responseTemplateName= data;
     },
    getIpcanTemplatesName(stepStoredValue,packetType,index){
         var value = {};
         var caller = this;
         var callbackArgs = {};
         callbackArgs["selectedFields"] = {};
         callbackArgs["selectedFields"]["packet"] = stepStoredValue.response.parameters.templateId;
         callbackArgs["selectedFields"]["connection"] = "";
         callbackArgs["selectedFields"]["connection"] = stepStoredValue.connectionId;

         value["application"] = index;
         value["stepType"] = packetType;
         value["selectedFields"] = callbackArgs["selectedFields"];
         let options = {
             function: "getIpcanStepTemplates",
             value: value,
             category: "Protocols",
             c_subType: "",
             n_systemId: 0,
             requestType: "ajax",
         };
        return caller.frameworkAxiosRequest({
             method: "post",
             url: "serve.php?f=testing&f2=configureAndRunTests",
             data: options,
         })
          .then(function (response) {
               //console.log(response);
               //console.log(response.data.result.json.templatesNames.packet);
               //  caller.responseTemplateName.push(response.data.result.json.templatesNames.packet);
                 return response.data.result.json.templatesNames.packet;
          })
          .catch(function (error) {
                 console.log(error);
             });

          //console.log(result);
         // console.log(this.responseTemplateName);
        },*/
   subTypeShow(source) {
      let subTypeName = '';
      if (
          typeof source['subType'] != 'undefined' &&
          source['subType'] != '' &&
          source['subType'] != 'HTTP' &&
          source['subType'] != 'PING' &&
          source['subType'] != 'SSH' &&
          source['subType'] != 'DIAMETER' &&
          source['subType'] != 'LDAP' &&
          source['subType'] != 'RADIUS'
      ) {
        subTypeName = source['subType'] + ' -';
      }
      return subTypeName;
    },

    findStepObjValue(source, needle) {
      return source.filter(function (type) {
        let result = '';
        if (type.command == needle) {
          result = type.url;
        }
        return result;
      });
    },
    renderStepHeader(stepStoredValue) {
      this.stepDurationText = '';
      this.stepApnText = '';
      this.countryFlagTitle = '';
      this.countryFlagSource = '';
      this.apnName = '';
      this.statusType = '';
      this.statusInfo = '';
      //this.showIcon = false;
      this.openURL = '';
      this.titleOpenURL = '';
      let index = this.stepType;
      //console.log(index);
      if (typeof stepStoredValue != 'undefined') {
        /*  if (
          typeof stepStoredValue != "undefined" &&
          typeof stepStoredValue.Condition != "undefined" &&
          stepStoredValue.Condition != ""
        ) {
          this.showIcon = true;
          //showRemoveConditionIcon($stepHeader, true);
        } else {
          this.showIcon = false;
          //showRemoveConditionIcon($stepHeader, false);
        }*/
        //console.log(index);
        if (index) {
          this.stepDurationText = '';
          this.stepApnText = '';

          var name = this.getMappedName(index);
          if (index.indexOf('RATTUX_') !== -1) {
            name = 'Global Client: ' + name;
            if (
                typeof stepStoredValue['network'] != 'undefined' &&
                typeof stepStoredValue['network'][0] != 'undefined' &&
                typeof stepStoredValue['network'][0]['country'] != 'undefined'
            ) {
              let countryVal = stepStoredValue['network'][0]['country'];
              this.renderFlag(countryVal);
            }
          } else if (
              checkDiameterProtocol(index) ||
              index === 'RADIUS_ACCESS' ||
              index === 'RADIUS_ACCOUNTING' ||
              index === 'RADIUS'
          ) {
            //console.log("diameter");
            if (typeof stepStoredValue != 'undefined') {
              let stepValue = stepStoredValue;
              // var $testCasesGroup = $stepTypeSelector.closest(".testCasesGroup");
              // var ipcanStepTemplates = $testCasesGroup.data("ipcanStepTemplates");
              let subType = getSubType(index, stepValue);
              var indexString =
                  '"' +
                  index +
                  ':' +
                  stepValue.connectionId +
                  ':' +
                  stepValue.templateId +
                  ':' +
                  subType +
                  '"';
              let localData;
              if (this.localData != null) {
                localData = this.localData;
              } else if (
                  typeof this.ipcanStepTemplates[indexString] != 'undefined'
              ) {
                localData = this.ipcanStepTemplates[indexString];
              }
              if(this.result.templateData) {
                const templateName = this.result.templateData[this.stepStoredValue.templateId];
                if (templateName) this.statusInfo = `: ${templateName}`;
              }
              if (typeof localData != 'undefined') {
                let templatesNames = localData['templatesNames'];
                if (index === 'RADIUS_ACCOUNTING') {
                  //  var radiusAccountingStatusTypesArray = $testCasesGroup.data(
                  //     "radiusAccountingStatusTypesArray"
                  //  );
                  let statusType =
                      this.radiusAccountingStatusTypesArray[this.stepID];

                  // let $statusInfo = $stepHeader.find(".stepApnText .statusInfo");
                  this.statusInfo = ': ' + templatesNames.packet + ': ';
                  //$statusInfo.append("<span class='statusType'></span>");
                  if (typeof statusType != 'undefined' && statusType !== '') {
                    // infoString+=statusType;

                    //$statusInfo.find(".statusType").text(statusType);
                    this.statusType = statusType;
                  }
                  // infoString+="</span>";
                  //  $stepHeader.find('.stepApnText .statusInfo').html(infoString);
                } else {
                  this.statusInfo = ': ' + templatesNames.packet + '';
                  // $stepHeader
                  //   .find(".stepApnText .statusInfo")
                  //   .text(": " + templatesNames.packet + "");
                }
              }
            }
          } else if (index === 'HTTP') {
            if (
                typeof stepStoredValue != 'undefined' &&
                typeof stepStoredValue['parameters'] != 'undefined'
            ) {
              this.sethttpFunctionHeader(
                  stepStoredValue['parameters'].templateId,
                  this.additionalStepsInfo['HTTP'],
                  stepStoredValue,
              );
            }
          } else if (index == 'Comments' || index == 'CALC') {
            // var spanName = ".stepApnText";
            // this.setCommentsHeader(stepStoredValue);
            if (
                typeof stepStoredValue == 'object' &&
                typeof stepStoredValue.value != 'undefined'
            ) {
              this.stepApnText = ': ' + stepStoredValue.value;
            }
          } else if (index == 'Pause') {
            let value = '';
            if (typeof stepStoredValue == 'object') {
              value = stepStoredValue['value'];
            } else {
              value = stepStoredValue;
            }

            this.setPauseHeader(value);
          } else if (index == 'LDAP') {
            let base = '';
            let type = '';
            if (
                typeof stepStoredValue != 'undefined' &&
                typeof stepStoredValue['req'] != 'undefined'
            ) {
              if (
                  typeof stepStoredValue.req != 'undefined' &&
                  typeof stepStoredValue.req[0] != 'undefined'
              ) {
                var req = stepStoredValue['req'][0];
                if (typeof stepStoredValue.req[0].base != 'undefined') {
                  base = req['base'];
                }
                type = req['type'];
              }
            }

            this.renderLdapHeader(type, base);
          }
          else if (index == 'SSH') {
            if (
                typeof stepStoredValue != 'undefined' &&
                typeof stepStoredValue['templateId'] != 'undefined'
            ) {
              var templateId = stepStoredValue['templateId'];
              this.renderSSHHeader(this.additionalStepsInfo['SSH'], templateId);
            }
          }
          else if (index == 'PING') {
            if (
                typeof stepStoredValue != 'undefined' &&
                typeof stepStoredValue['connectionId'] != 'undefined'
            ) {
              var connectionId = stepStoredValue['connectionId'];
              this.renderPingHeader(
                  this.additionalStepsInfo['SSH'],
                  connectionId,
              );
            }
          }
          else if (index == 'Explorer_PING') {
            this.renderExPingHeader(stepStoredValue);
          } else if (index == 'Explorer_Traceroute') {
            this.renderExTraceroute(stepStoredValue);
          } else if (index == 'Explorer_DNS') {
            this.renderExDNSHeader(stepStoredValue);
          } else if (index == 'Explorer_iperf') {
            this.renderExIperfHeader(stepStoredValue);
          } else if (index == 'Explorer_UDPST') {
            this.renderExUDPSTHeader(stepStoredValue);
          }  else if (index == 'Explorer_SIP_CALL') {
            //console.log(this.stepStoredValue);
            this.renderExSIPCALLHeader(stepStoredValue);
          } else if (index == 'Explorer_librespeedtest') {
            this.renderExLibreSpeedTestHeader(stepStoredValue);
          } else if (index == 'Explorer_Speedtest') {
            this.renderExSpeedHeader();
          } else if (index == 'Explorer_cellular_attach') {
            this.renderExCellularAttHeader(stepStoredValue);
          }else if (index == 'Explorer_cellular_data_connect') {
            this.renderExCellularDataConnHeader(stepStoredValue);
          }else if (index == 'Explorer_TWAMP') {
            this.renderExTwampHeader(stepStoredValue);
          }else if (index == 'Explorer_USSD') {
            this.renderExUssdHeader(stepStoredValue);
          }else if (index == 'Explorer_SMS') {
            this.renderExSMSHeader(stepStoredValue);
          }else if (index == 'Explorer_cellular_voice_call') {
            this.renderExCellularVoiceCallHeader(stepStoredValue);
          } else if (index == 'STARTTEST') {
            if (typeof stepStoredValue.testcaseName !== 'undefined') {
              this.stepApnText = ': ' + stepStoredValue.testcaseName;
            }
          } else if (index == 'expect') {
           let rTemplateName = "";
           let getURI = "";
           if (
                typeof stepStoredValue.parameters !== 'undefined' &&
                typeof stepStoredValue.parameters.packetType !== 'undefined' &&
                typeof stepStoredValue.parameters.expectType !== 'undefined'
            ) {
              let expectType = stepStoredValue.parameters.expectType;
              let packetType = stepStoredValue.parameters.packetType;
              if (packetType == 'DIAMETER') {
                packetType = this.getMappedName(
                    stepStoredValue.parameters.packetType,
                );
              }
              if (packetType === 'HTTP') {
                 //careful this one give axio result
                 //this.getTemplateName(stepStoredValue,index,packetType);
                  if(typeof stepStoredValue.check!=='undefined' &&
                     typeof stepStoredValue.check.method!=='undefined' &&
                     typeof stepStoredValue.check.uri!=='undefined' &&
                     stepStoredValue.check!=='' &&
                     stepStoredValue.check.method!=='' &&
                     stepStoredValue.check.uri!==''){
                     getURI = ": "+stepStoredValue.check.method+" "+stepStoredValue.check.uri;
                  }
                  let responseTemplateID = stepStoredValue.response.parameters.templateId;
                  let responseTemplateName = this.result.additional.responseTemplateList[index][responseTemplateID];
                  if(responseTemplateName!=='' & typeof responseTemplateName!=='undefined'){
                      rTemplateName = ": "+responseTemplateName;
                  }
              }
              testCases['expect']['expectType']['options'].forEach(function (value) {
                  if (expectType == value[0]) {
                  expectType = value[1];
                  return;
                }
              });
              this.expectHeader(packetType, expectType+getURI+rTemplateName);
            }
          } else if (index == 'Web_Driver') {
            if (typeof stepStoredValue.commands != 'undefined') {
              let currentStep = this.findStepObjValue(
                  stepStoredValue.commands,
                  'open',
              );
              if (
                  currentStep.length != 0 &&
                  typeof currentStep[0] !== 'undefined' &&
                  typeof currentStep[0].url !== 'undefined'
              ) {
                this.titleOpenURL = currentStep[0].url;
                this.openURL = ' - ' + trimString(currentStep[0].url, 50); //Current Step
              } else {
                //check prev
                if (
                    typeof this.templateValue != 'undefined' &&
                    typeof this.stepID != 'undefined'
                ) {
                  var stepID = this.stepID;
                  var stepDetails = this.templateValue;
                  var status = false;
                  Object.entries(stepDetails).forEach((entry) => {
                    const [key, value] = entry;
                    if (
                        key <= stepID &&
                        typeof value.Web_Driver != 'undefined'
                    ) {
                      //check Prev
                      let stepResult = this.findStepObjValue(
                          value.Web_Driver.commands,
                          'open',
                      );
                      if (
                          stepResult.length != 0 &&
                          typeof stepResult[0] !== 'undefined' &&
                          typeof stepResult[0].url !== 'undefined'
                      ) {
                        status = true;
                      }
                    }
                  });
                  if (status === true) {
                    this.openURL = '- resumed';
                    this.titleOpenURL = 'resumed';
                  } else {
                    this.openURL = '';
                  }
                }
              }
            }
          }
          if (
              typeof stepStoredValue != 'undefined' &&
              typeof stepStoredValue['parameters'] != 'undefined' &&
              typeof stepStoredValue['parameters']['calledStationId'] !=
              'undefined'
          ) {
            let calledStationId =
                stepStoredValue['parameters']['calledStationId'];
            // var additionalStepsInfo = $fieldsObject.data("additionalStepsInfo");
            let calledStations = this.additionalStepsInfo['calledStations'];
            let apnName = calledStations[calledStationId]['name'];
            let apnText = this.getApnText(index, apnName);
            this.apnName = apnText;
          }
          this.stepTypeText = ' ' + name;
        }
      }
    },

    // ,showRemoveConditionIcon($stepHeader, showIcon) {
    //   if (showIcon) {
    //     $stepHeader
    //       .find(".conditionInfoContainer")
    //       .html('<i class="f05a fas fa-info-circle infoCircle"></i>');
    //   } else {
    //     $stepHeader.find(".conditionInfoContainer").html("");
    //   }
    // }

    renderFlag(countryVal) {
      var cName = this.getCountryName(countryVal);
      if (typeof cName[1] != 'undefined') {
        this.countryFlagTitle = cName[0];
        this.countryFlagSource = cName[1];
      }
    },

    getCountryName(countryVal) {
      var findValue = [];
      var allCountryList = this.result['additional']['rattuxCountries'];
      allCountryList.forEach(function (value) {
        if (value[0] == countryVal) {
          findValue[0] = value[1];
          findValue[1] = value[6];
        }
        return findValue;
      });
      return findValue;
    },

    getApnText(type, apnValue) {
      let apnText = '';
      if (type == 'RADIUS_ACCESS' || type == 'RADIUS_ACCOUNTING' || type == 'RADIUS') {
        apnText = ': ' + apnValue;
      } else {
        apnText = ' - ' + apnValue;
      }
      return apnText;
    },

    sethttpFunctionHeader(templateId, httpInfo, stepStoredValue) {
      var name = '';
      //  var desc = "";
      if (typeof templateId != 'undefined' && templateId != '') {
        if (
            typeof httpInfo != 'undefined' &&
            typeof httpInfo['tempNamesArray'] != 'undefined' &&
            typeof httpInfo['tempNamesArray'][templateId] != 'undefined'
        ) {
          name = httpInfo['tempNamesArray'][templateId];
        } else {
          // fetch names
          let caller = this;
          caller.frameworkAxiosRequest({
            method: 'post',
            url: 'serve.php?f=testing&f2=configureAndRunTests',
            data: {
              function: 'getHTTPClientServer',
              subType: stepStoredValue.subType,
            },
          })
              .then(function (post) {
                let httpNewInfo = post.data.result.json;
                if (
                    typeof caller.result.additional.additionalStepsInfo[
                        caller.stepType
                        ] == 'undefined'
                ) {
                  caller.$set(
                      caller.result.additional.additionalStepsInfo,
                      caller.stepType,
                      {},
                  );
                }
                caller.$set(
                    caller.result.additional.additionalStepsInfo[caller.stepType],
                    'tempNamesArray',
                    httpNewInfo.tempNamesArray,
                );
                caller.$store.commit('dialogResultChanged', caller.result);
                // caller.sethttpFunctionHeader(
                //   templateId,
                //   caller.additionalStepsInfo["HTTP"],
                //   stepStoredValue
                // );
                let httpInfo = caller.additionalStepsInfo['HTTP'];
                if (
                    typeof httpInfo != 'undefined' &&
                    typeof httpInfo['tempNamesArray'] != 'undefined' &&
                    typeof httpInfo['tempNamesArray'][templateId] != 'undefined'
                ) {
                  name = httpInfo['tempNamesArray'][templateId];
                  caller.setHttpName(name);
                }
              })
              .catch((error) => {
                console.log(error);
              });
        }
        /* if (typeof parameter.desc !="undefined" ){
                   desc=parameter.desc;
                 }*/
        this.setHttpName(name);
      }
      // this.stepApnText=text);
      //}
    },
    setHttpName(name) {
      var desc = '';
      var text = ': ';
      if (name != '') {
        text += name;
      }
      if (name != '' && desc != '') {
        text += ': ';
      }
      if (desc != '') {
        text += desc;
      }
      this.stepApnText = text;
    },

    setCommentsHeader(value) {
      let shownString = ' ',
          limited = '';

      if (typeof value != 'undefined' && typeof value == 'string') {
        limited = value.substr(0, 75); //Allow only 75 Character !!
      } else if (
          typeof value == 'object' &&
          typeof value.value != 'undefined'
      ) {
        limited = value.value.substr(0, 75); //Allow only 75 Character !!
      } else if (
          typeof value == 'object' &&
          typeof value.description != 'undefined'
      ) {
        limited = value.description.substr(0, 75); //Allow only 75 Character !!
      }
      if (limited != '') {
        shownString = ': ' + limited + '';
      }
      this.stepApnText = shownString;
      // $stepGroup.find(spanName).text(shownString);
    },

    setPauseHeader(value) {
      if (value != '' && typeof value != 'undefined') {
        this.stepApnText = ': ' + value + ' seconds';
      }
    },

    renderLdapHeader(ldapType, base) {
      let ldapOperations = options.ldapOperations;
      var typeTranslation = findInArray(ldapType, ldapOperations);

      let typeName = '';
      if (base != '') {
        typeName = ': ' + base;
      }
      if (ldapType == 'add_ldif' || ldapType == 'delete_list') {
        typeName = '';
      }
      if (typeof typeTranslation != 'undefined' && typeTranslation != '') {
        this.stepApnText = '-' + typeTranslation + typeName;
      }
    },

    renderSSHHeader(additionalStepsInfo, templateId) {
      if (
          typeof additionalStepsInfo != 'undefined' &&
          typeof additionalStepsInfo['tempNamesArray'] != 'undefined' &&
          typeof additionalStepsInfo['tempNamesArray'][templateId] != 'undefined'
      ) {
        this.stepApnText =
            ': ' + additionalStepsInfo['tempNamesArray'][templateId];
      }
    },
    renderPingHeader(additionalStepsInfo, connectionId) {
      if (
          typeof additionalStepsInfo != 'undefined' &&
          typeof additionalStepsInfo['connectionNamesObject'] != 'undefined' &&
          typeof additionalStepsInfo['connectionNamesObject'][connectionId] !=
          'undefined'
      ) {
        this.stepApnText =
            ': ' + additionalStepsInfo['connectionNamesObject'][connectionId];
      }
    },
    renderExPingHeader(stepInfo) {
      if (typeof stepInfo['target'] != 'undefined') {
        this.stepApnText = ': ' + stepInfo['target'];
      }
      if (typeof stepInfo['ip'] != 'undefined') {
        this.stepApnText =
            this.stepApnText + ' (' + ipString(stepInfo['ip']) + ')';
      }
    },
    renderExTraceroute(stepInfo) {
      // console.log(stepInfo);
      if (
          typeof stepInfo['target'] != 'undefined' &&
          typeof stepInfo['ip'] != 'undefined'
      ) {
        this.stepApnText =
            ': ' + stepInfo['target'] + ' (' + ipString(stepInfo['ip']) + ')';
      }
    },
    renderExDNSHeader(stepInfo) {
      //console.log(stepInfo);
      if (
          typeof stepInfo['server'] != 'undefined' &&
          typeof stepInfo['domain'] != 'undefined' &&
          typeof stepInfo['recordType'] != 'undefined'
      ) {
        this.stepApnText =
            ': Request \'' +
            stepInfo['recordType'] +
            '\'-record of \'' +
            stepInfo['domain'] +
            '\' at \'' +
            stepInfo['server'];
        let ip = 'ipv4'; //default
        if (typeof stepInfo['ip'] != 'undefined') {
          ip = stepInfo['ip'];
        }
        this.stepApnText = this.stepApnText + ' (' + ipString(ip) + ')';
      }
    },
    renderExSpeedHeader() {
      this.stepApnText = ': nearest server';
    },
    renderExCellularAttHeader(stepInfo) {
      let simSlot = '';
      let simType = '';
      let network = '';
      let ratType = '';
      if (typeof stepInfo.parameters!= 'undefined' &&
          typeof stepInfo.parameters.sim != 'undefined') {
        simSlot = stepInfo.parameters.sim.slot;
        simType = stepInfo.parameters.sim.type;
      }
      if(typeof stepInfo.parameters!= 'undefined' &&
          typeof stepInfo.parameters.network != 'undefined') {
        network = stepInfo.parameters.network;
      }
      if(typeof stepInfo.parameters!= 'undefined' &&
          typeof stepInfo.parameters.radio != 'undefined') {
        ratType = stepInfo.parameters.radio.ratType;
      }
      this.stepApnText = ': SIM: ' + simType + ', Slot: '+simSlot+', Network: '+network+', '+ratType;
    },
    renderExCellularDataConnHeader(stepInfo) {
      let apn = '';
      let userDetails = '';
      let user= '';
      if (typeof stepInfo.parameters!= 'undefined') {
        apn = stepInfo.parameters.apn;
      }
      if (typeof stepInfo.parameters!= 'undefined' && typeof stepInfo.parameters.user!= 'undefined' && stepInfo.parameters.user!= '') {
        user = stepInfo.parameters.user;
        userDetails = ', User: '+user;
      }
     this.stepApnText = ': APN: ' + apn + userDetails;
    },
    renderExUssdHeader(stepInfo) {
      let code = '';
      if (typeof stepInfo.parameters!= 'undefined') {
        code = this.truncateStr(stepInfo.parameters.code,30);
      }
     this.stepApnText = ': ' + code;
    },
    renderExSMSHeader(stepInfo) {
      let targetNumber = '';
      if (typeof stepInfo.parameters!= 'undefined' && typeof stepInfo.parameters.targetNumber!= 'undefined') {
        targetNumber = stepInfo.parameters.targetNumber;
      }
     this.stepApnText = ': ' + targetNumber;
    },
    renderExCellularVoiceCallHeader(stepInfo) {
      let targetNumber = '';
      let type = '';
      let typeDetails = '';
      if (typeof stepInfo.parameters!= 'undefined' && typeof stepInfo.parameters.targetNumber!= 'undefined') {
        targetNumber = stepInfo.parameters.targetNumber;
      }
      if (typeof stepInfo.parameters!= 'undefined' && typeof stepInfo.parameters.type!= 'undefined') {
        type = stepInfo.parameters.type;
        typeDetails = ',Type: '+type;
      }
     this.stepApnText = ': ' + targetNumber+typeDetails;
    },
    renderExTwampHeader(stepInfo) {
      let host = '';
      let port = '';
      let ip = '';
      if (typeof stepInfo.target!= 'undefined') {
        host = stepInfo.target.host;
        ip = stepInfo.target.ip;
        port = stepInfo.target.port;
      }
      this.stepApnText = ': ' + host + ' (' + ip + ') : '+port;
    },

    renderExLibreSpeedTestHeader(stepInfo) {
      let domain = '';
      if (
          typeof stepInfo.target != 'undefined' &&
          typeof stepInfo.target.host != 'undefined' &&
          stepInfo.target.host != '' &&
          stepInfo.target.type == 'static'
      ) {
        domain = stepInfo.target.host;
      } else if (
          typeof stepInfo.target != 'undefined' &&
          stepInfo.target.type == 'resource' &&
          typeof stepInfo.target.resourceTag != 'undefined'
      ) {
        domain = stepInfo.target.resourceTag;
      } else if (typeof stepInfo.host != 'undefined' && stepInfo.host != '') {
        domain = stepInfo.host;
      }
      // if (
      //   typeof stepInfo["target"] != "undefined" &&
      //   typeof stepInfo["target"]["host"] != "undefined" &&
      //   stepInfo["target"]["host"] != ""
      // ) {
      let ip = 'ipv4'; //default
      if (typeof stepInfo['target'] != 'undefined' && typeof stepInfo['target']['ip'] != 'undefined') {
        ip = stepInfo['target']['ip'];
      }
      this.stepApnText = ': ' + domain + ' (' + ipString(ip) + ')';
      // }
    },
    renderExIperfHeader(stepInfo) {
      // console.log("-->",stepInfo);
      if (
          typeof stepInfo['target'] != 'undefined' &&
          typeof stepInfo['target']['host'] != 'undefined' &&
          typeof stepInfo['target']['port'] != 'undefined' &&
          typeof stepInfo['downlink'] != 'undefined' &&
          typeof stepInfo['uplink'] != 'undefined'
      ) {
        let protocol = '';
        let port = '';
        if (typeof stepInfo['target']['protocol'] != 'undefined') {
          protocol = stepInfo['target']['protocol'].toUpperCase();
        } else if (typeof stepInfo['protocol'] != 'undefined') {
          protocol = stepInfo['protocol'].toUpperCase();
        }
        if (typeof stepInfo['target']['port'] != 'undefined') {
          port = stepInfo['target']['port'];
        } else if (typeof stepInfo['port'] != 'undefined') {
          port = stepInfo['port'];
        }
        let ip = 'IPv4';
        if (typeof stepInfo['target']['ip'] != 'undefined') {
          ip = ipString(stepInfo['target']['ip']);
        }

        this.stepApnText =
            ': ' +
            protocol +
            ' to \'' +
            stepInfo['target']['host'] +
            ':' +
            port +
            '\' ' +
            this.getLinkDetails(stepInfo) +
            ' (' +
            ip +
            ')';
      }
    },
    getLinkDetails(stepInfo) {
      let downlink = '';
      let uplink = '';
      let linkDetails = '';
      if (stepInfo['downlink'] == 1) {
        downlink = 'downlink';
      }
      if (stepInfo['uplink'] == 1) {
        uplink = 'uplink';
      }
      if (downlink != '' && uplink != '') {
        linkDetails = ' (' + downlink + ' + ' + uplink + ')';
      } else if (downlink != '') {
        linkDetails = ' (' + downlink + ')';
      } else if (uplink != '') {
        linkDetails = ' (' + uplink + ')';
      } else {
        linkDetails = '';
      }
      return linkDetails;
    },
    renderExUDPSTHeader(stepInfo) {
      console.log('HEADER', stepInfo);
      let domain = '';
      if (typeof stepInfo.parameters != 'undefined') {
        if (
            typeof stepInfo.parameters.target.host != 'undefined' &&
            typeof stepInfo.parameters.target.port != 'undefined' &&
            stepInfo.parameters.target.host != '' &&
            stepInfo.parameters.target.port != '' &&
            stepInfo.parameters.target.type == 'static'
        ) {
          domain =
              stepInfo.parameters.target.host +
              ':' +
              stepInfo.parameters.target.port;
        } else if (stepInfo.parameters.target.type == 'resource') {
          domain = stepInfo.parameters.target.resourceTag;
        } else if (
            typeof stepInfo.parameters.host != 'undefined' &&
            typeof stepInfo.parameters.port != 'undefined' &&
            stepInfo.parameters.host != '' &&
            stepInfo.parameters.port != ''
        ) {
          domain = stepInfo.parameters.host + ':' + stepInfo.parameters.port;
        }
        if (
            typeof stepInfo != 'undefined' &&
            typeof stepInfo.parameters != 'undefined' &&
            typeof stepInfo.parameters.target != 'undefined' &&
            typeof stepInfo.parameters.target.ip != 'undefined'
        ) {
          // this.stepApnText =  ": " +stepInfo.parameters.target.host+":"+stepInfo.parameters.target.port + " ("+ipString(stepInfo.parameters.target.ip)+")";
          this.stepApnText =
              ': ' +
              domain +
              this.getLinkDetails(stepInfo['parameters']) +
              ' (' +
              ipString(stepInfo.parameters.target.ip) +
              ')';
        } else if (
            typeof stepInfo != 'undefined' &&
            typeof stepInfo.parameters != 'undefined' &&
            typeof stepInfo.parameters.target.ip != 'undefined'
        ) {
          this.stepApnText =
              ': ' +
              domain +
              this.getLinkDetails(stepInfo['parameters']) +
              ' (' +
              ipString(stepInfo.parameters.target.ip) +
              ')';
        }
      }
      //  console.log(this.stepApnText);
    },
    renderExSIPCALLHeader(stepInfo) {
      //console.log('HEADER', stepInfo);
      let domain = '';
      if (typeof stepInfo.parameters != 'undefined') {
        if (
            typeof stepInfo.parameters.target.host != 'undefined' &&
            stepInfo.parameters.target.host != '' &&
            stepInfo.parameters.target.type == 'static'
        ) {
          domain =
              stepInfo.parameters.target.host
        } else if (stepInfo.parameters.target.type == 'resource') {
          domain = stepInfo.parameters.target.resourceTag;
        } else if (
            typeof stepInfo.parameters.host != 'undefined' &&
            stepInfo.parameters.host != ''
        ) {
          domain = stepInfo.parameters.host;
        }
        this.stepApnText = ": "+domain;
      }
      //  console.log(this.stepApnText);
    },

    expectHeader(packetName, methodName) {
      // let $step = source.closest(".stepGroup");
      let contentTitle = '';
      if (packetName != '' && methodName != '') {
        contentTitle = '  ' + packetName + ': ' + methodName;
      }
      this.stepApnText = contentTitle;
    },
  },
};
</script>
<style>
.countryFlagImage {
  max-height: 20px;
}

.apnName,
.stepApnText {
  /*  display: inline-block;
     white-space: nowrap;
     overflow: hidden;
     overflow-x: hidden;
     overflow-hidden;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
       position: relative;
       top: 2px;*/
  max-width: 900px;
}

/* .stepInfoContainer {
  position: relative;
  top: 11px;
} */
.stepTypeText,
.stepApnText {
  position: relative;
  top: 1px;
}
</style>