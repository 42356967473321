<template>
  <div class="d-flex" :id="'selected_step_'+stepType">
    <v-autocomplete
      @keydown.enter.native.prevent
      v-model="inputValue"
      :value="value"
      :items="options"
      label="Step Type"
      item-text="text"
      item-value="selector"
      :rules="rules"
      outlined
      dense
      :auto-select-first="true"
      :disabled="disabled"
      :id="id"
      :error-messages="errors"
      @input="inputChanged()"
      return-object
    >
      <!-- <template slot="selection" slot-scope="data">
      <v-chip
        :selected="data.selected"
        close
        class="chip--select-multi"
        @input="remove(data.item)"
      >{{ data.item.name }}</v-chip>
      </template>-->

      <template slot="item" slot-scope="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content :id="id + '_' + (data.item.selectedValue ? data.item.selectedValue : data.item.value)">
            <v-list-item-title
              style="font-weight: unset !important"
              v-html="data.item.text"
            ></v-list-item-title>
            <!-- <v-list-tile-sub-title v-html="data.item.group"></v-list-tile-sub-title> -->
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
    <HelpButton :id="'selected_step_'+stepType"/>
  </div>
</template>
<script>
import { getSelectRules } from "@/js/validators.js";
import HelpButton from "../components/basic/HelpButton.vue";

export default {
  components: {HelpButton},
  data() {
    return { errors: [] };
  },
  created() {
    let errors = [];
    this.rules.forEach((rule) => {
      let text = rule(this.value);
      if (typeof text == "string") {
        errors.push(text);
      }
    });
    this.errors = errors;
  },
  computed: {
    rules() {
      return getSelectRules({ class: "required" }, this.result, this);
    },
    disabled() {
      let disabled = false;
      //console.log(this.result);
      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },
    inputValue: {
      get: function () {
        return this.value;
      },
      set: function (selectedValue) {
        if (selectedValue != null) {
          this.$emit("input", selectedValue);
        }
      },
    },
  },

  props: {
    value: {},
    options: Array,
    result: {},
    id: {},
    stepType: String,
  },
  methods: {
    inputChanged() {
      this.errors = [];
      this.$store.commit("setFieldDataMutated", true);
    },
    getSelectRules: getSelectRules,
  },
};
</script>
<style>
.v-list > .v-subheader {
  font-weight: 500;
  font-size: 16px;
  color: black;
}
</style>