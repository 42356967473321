
<template>
  <div>
    <associatedOption
      :fieldAttributes="{
        name: 'Type',
        associatedOption: [
          ['array', 'Array'],
          ['object', 'Object'],
        ],
      }"
      type="select"
      :fieldAttrInput="{ class: 'required', id: 'httpBodyJSONType' }"
      v-model="rootType"
      :templateContent="result"
    ></associatedOption>
    <v-row
      v-if="
        typeof localValue.content != 'undefined' &&
        Object.keys(localValue.content).length > 0
      "
      no-gutters
      class="mb-2"
    >
      <v-col class="text-subtitle-1"> Attribute </v-col>

      <v-col style="max-width: 40px; min-width: 40px" class="mt-2"> </v-col
      ><v-col class="text-subtitle-1"> Type </v-col>
      <v-col class="ml-2 text-subtitle-1"> Content </v-col>
      <v-col class="ml-2 mt-2" style="max-width: 40px"> </v-col>
    </v-row>
    <v-form
      :lazy-validation="true"
      ref="addingLineForm"
      v-model="addingFormValid"
    >
      <jsonContentRecursive
        v-model="localValue"
        :result="result"
        :treeLength="1"
      ></jsonContentRecursive>
      <primaryButton
        class="mr-2 mt-3"
        label="Add Attribute"
        icon="mdi-plus"
        @click="addLine()"
        type="button"
        color="primary"
        :attributesArray="{ id: 'httpBodyJSON_mainAdder' }"
      ></primaryButton>
    </v-form>
  </div>
</template>
<script>
import jsonContentRecursive from "@/components/legacy/jsonContentRecursive.vue";
import associatedOption from "@/commonComponents/associatedOption.vue";
// import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
// import lineButtons from "@/cfg/lineButtons.json";
export default {
  components: {
    primaryButton,
    // lineFieldsArray,
    associatedOption,
    jsonContentRecursive,
  },

  props: ["storedBody", "result"],
  data() {
    return {
      addingFormValid: true,
      types: [
        ["array", "Array"],
        ["string", "String"],
        ["boolean", "Boolean"],
        ["null", "Null"],
        ["number", "Number"],
        ["object", "Object"],
      ],
      // cachedPairArray: null,
    };
  },
  watch: {
    rootType(newVal, oldVal) {
      if (newVal != oldVal) {
        let storedBody = { ...this.storedBody };
        if (newVal == "array") {
          this.$set(storedBody, "content", []);
        } else {
          this.$set(storedBody, "content", {});
        }
        this.$emit("stored-body-changed", storedBody);
      }
    },
  },
  computed: {
    localValue: {
      get() {
        if (typeof this.storedBody.type == "undefined") {
          let storedBody = { ...this.storedBody };
          this.$set(storedBody, "type", "object");
          this.$set(storedBody, "content", {});

          this.$emit("stored-body-changed", storedBody);
        }
        return this.storedBody;
      },
      set(newVal) {
        this.$emit("stored-body-changed", newVal);
      },
    },
    rootType: {
      get() {
        let rootType = "object";
        let storedBody = this.storedBody;
        if (
          typeof storedBody == "object" &&
          typeof storedBody.type != "undefined"
        ) {
          rootType = storedBody.type;
        }
        return rootType;
      },
      set(newVal) {
        let storedBody = { ...this.storedBody };

        this.$set(storedBody, "type", newVal);

        this.$emit("stored-body-changed", storedBody);
      },
    },
  },

  methods: {
    addLine() {
      if (this.$refs.addingLineForm.validate()) {
        let line = { type: "string", content: "" };
        let storedBody = { ...this.storedBody };
        if (storedBody.type == "array") {
          if (
            typeof storedBody.content != "object" ||
            !Array.isArray(storedBody.content)
          ) {
            storedBody.content = [];
          }
          storedBody.content.push(line);
        } else {
          storedBody.content[""] = line;
        }
        this.$emit("stored-body-changed", storedBody);
      }
    },
    // checkClicked(index, name) {
    //   console.log(index, name);
    //   if (name == "addGroup") {
    //     let request = { ...this.storedBody.content };
    //     let lineInfo = this.pairArray[index];
    //     let tree = lineInfo.tree;
    //     let treeArray = tree.split(".");
    //     this.findlocationAndAdd(request, treeArray, 0, lineInfo);
    //     // emtyping cached array since it is manipulating parent
    //     // this.cachedPairArray = null;
    //     this.$emit("input", request);
    //   }
    // },
    // calculatePairArray(pairArray, sentValue, index, parentType) {
    //   //let pairArray = [];
    //   //console.log(sentValue);
    //   for (const [key, valueArray] of Object.entries(sentValue)) {
    //     pairArray = this.getInnerTree(
    //       pairArray,
    //       key,
    //       valueArray,
    //       "",
    //       index,
    //       parentType
    //     );
    //     // pairArray = [...pairArray, ...childArray];
    //   }
    //   return pairArray;
    // },
    // getInnerTree(pairArray, key, value, i, index, parentType) {
    //   //  for (const [i, value] of Object.entries(valueArray)) {
    //   //let pairArray = [];
    //   //let type;

    //   let content = value.content;
    //   if (typeof content == "object" && value.content != null) {
    //     content = {};
    //     if (Array.isArray(value.content)) {
    //       content = [];
    //     }
    //   }
    //   //console.log(key);
    //   let parentIndex = "";
    //   if (index != "") {
    //     parentIndex = index + ".";
    //   }
    //   // let currentTree = parentIndex + key + "." + i;
    //   let currentTree = parentIndex + key;
    //   pairArray.push({
    //     attribute: key,
    //     ...value,
    //     content: content,
    //     // type: type,
    //     tree: currentTree,
    //     parentType: parentType,
    //   });

    //   if (value.content != null && typeof value.content == "object") {
    //     pairArray = this.calculatePairArray(
    //       pairArray,
    //       value.content,
    //       currentTree,
    //       value.type
    //     );
    //     // pairArray = [...pairArray, ...childArray];
    //   }
    //   // }
    //   return pairArray;
    // },
    // getFieldAttrRow(valueLine) {
    //   //console.log("color called", valueLine);
    //   let attrs = {};
    //   if (typeof valueLine.color != "undefined") {
    //     attrs = { style: "color: " + valueLine.color };
    //   }
    //   return attrs;
    // },
    // checkInput(newValueArray) {
    //   //this.cachedPairArray = newValueArray;
    //   //console.log(newValueArray);
    //   let request = {};
    //   //let request = { ...this.storedBody.content };
    //   newValueArray.forEach((line) => {
    //     let content = line.content;

    //     let insertedLine = {
    //       content: content,
    //       type: line.type,
    //     };
    //     if (line.type == "null") {
    //       this.$delete(insertedLine, "content");
    //     }
    //     // let treeLocation = request;
    //     let treeArray = line.tree.split(".");
    //     this.findlocationAndupdate(insertedLine, request, treeArray, 0, line);
    //   });
    //   let value = { type: this.rootType, content: request };
    //   //console.log(JSON.stringify(value));
    //   this.$emit("stored-body-changed", value);
    // },
    // findlocationAndupdate(
    //   insertedLine,
    //   updatingObject,
    //   treeArray,
    //   location,
    //   line
    // ) {
    //   // let index = treeArray[location];
    //   if (typeof treeArray[location + 1] != "undefined") {
    //     // treeArray.forEach((index, i) => {
    //     //console.log(index, JSON.stringify(updatingObject));

    //     if (typeof updatingObject[treeArray[location]] != "undefined") {
    //       if (
    //         typeof updatingObject[treeArray[location]].content == "undefined"
    //       ) {
    //         //this.$set(updatingObject, insertedAttr, []);
    //         if (line.type == "object") {
    //           this.$set(updatingObject[treeArray[location]], "content", {});
    //         } else if (line.type == "array") {
    //           this.$set(updatingObject[treeArray[location]], "content", []);
    //         }
    //       }

    //       this.findlocationAndupdate(
    //         insertedLine,
    //         updatingObject[treeArray[location]].content,
    //         treeArray,
    //         location + 1,
    //         line
    //       );
    //     }
    //   } else {
    //     let insertedAttr = line.attribute;

    //     this.$set(updatingObject, insertedAttr, insertedLine);
    //   }
    // },
    // findlocationAndAdd(updatingObject, treeArray, location, lineInfo) {
    //   console.log(treeArray, location);
    //   // let index = treeArray[location];

    //   if (
    //     typeof updatingObject[treeArray[location]].content == "undefined" ||
    //     updatingObject[treeArray[location]].content == ""
    //   ) {
    //     //this.$set(updatingObject, insertedAttr, []);
    //     if (lineInfo.type == "object") {
    //       this.$set(updatingObject[treeArray[location]], "content", {});
    //     } else if (lineInfo.type == "array") {
    //       this.$set(updatingObject[treeArray[location]], "content", []);
    //     }
    //   }
    //   if (typeof treeArray[location + 1] != "undefined") {
    //     this.findlocationAndAdd(
    //       updatingObject[treeArray[location]].content,
    //       treeArray,
    //       location + 1,
    //       lineInfo
    //     );
    //   } else {
    //     let newLine = {
    //       content: "",
    //       type: "string",
    //     };
    //     if (lineInfo.type == "array") {
    //       updatingObject[treeArray[location]].content.push(newLine);
    //     } else if (lineInfo.type == "object") {
    //       updatingObject[treeArray[location]].content[""] = newLine;
    //     }
    //   }
    // },
  },
};
</script>