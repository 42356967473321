<template>
  <div>
    <lineFieldsArray
      :fieldAttrRow="{}"
      :fields="fields"
      :templateContent="result"
      :value="pairArray"
      @line-field-input="checkInput(...arguments)"
      :headerTitle="headerTitle"
      :addable="addable"
      :updateOnChange="true"
      :isValid="isValid"
      @revalidate="$emit('revalidate')"
      :linePrefix="linePrefix"
      :hideDetails="hideDetails"
    ></lineFieldsArray>
  </div>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import lineButtons from "@/cfg/lineButtons.json";
import { getRandomInt } from "@/js/helper.js";
export default {
  components: { lineFieldsArray },
  data() {
    return {};
  },
  props: {
    hideDetails: { type: Boolean, default: false },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    isValid: {
      type: Boolean,
    },
    addable: { type: Boolean, default: true },
    headerTitle: { type: Boolean },
    attrSource: {},
    isTemplate: { type: Boolean, default: false },
    deletable: { type: Boolean, default: true },
    result: {},
    linePrefix: {},
  },
  /*  created() {
    this.$nextTick(function () {
      this.$emit("revalidate");
    });
  },*/
  computed: {
    fields() {
      let fields = {
        name: {
          type: "text",
          inputFieldFormatter: "httpParametersInput",
          name: "Attribute",
          fieldAttrInput: { class: "required" },
        },
        value: {
          type: "text",
          inputFieldFormatter: "httpParametersInput",
          name: "Value",
          fieldAttrInput: { class: "required" },
        },
        remove: lineButtons.remove,
        fix: lineButtons.fix,
        source: {
          type: "hidden",
          colAttrs: { class: "d-none" },
          default: this.attrSource,
        },
        mandatory: {
          type: "hidden",
          colAttrs: { class: "d-none" },
        },
      };

      this.$set(fields.remove, "inputFieldFormatter", "diameterTrash");
      this.$set(fields.remove, "isTemplate", this.isTemplate);
      this.$set(fields.remove, "source", this.attrSource);

      if (this.attrSource != "t_testcases") {
        fields.fix = {
          type: "hidden",
          colAttrs: { class: "d-none" },
          default: "0",
        };
      }
      if (this.deletable == false) {
        this.$delete(fields, "remove");
      }
      return fields;
    },
    pairArray: {
      get() {
        let pairArray = [];

        if (typeof this.value != "undefined") {
          pairArray = [...this.value];

          // let readOnly = false;
          // if (!this.addable) {
          //   readOnly = true;
          // }
          pairArray.forEach((element) => {
            let readOnly = false;
            if (element.fix == "2") {
              readOnly = true;
            }
            if (typeof element.atRanId == "undefined") {
              let atRanId = "";
              atRanId = getRandomInt(0, 100000);
              this.$set(element, "atRanId", atRanId);
            }
            if (readOnly) {
              this.$set(element, "readOnly", readOnly);
            }
          });
        }

        return pairArray;
      },
      // set(newValue) {
      //     console.log("new value",newValue);
      // }
    },
  },
  methods: {
    checkInput(value) {
      this.$emit("input", value);
    },
  },
};
// function getAttributeValueAdderContentParameterTab(
//   $parametersContainer,
//   parameters
// ) {
//   let column = "";
//   let $fieldsObject = $parametersContainer.closest(".fields");
//   if ($fieldsObject.data("enableSuppress")) {
//     column = '<td class="httpSuppress  suppress">Suppress</td>';
//   }
//   // var removeButton=  "<a href='javascript:void(0);' onclick='removeHeaderVar(this)' title='Delete command'><i class='fas fa-trash fs16 iconRed' ></i></a>";
//   var addButton =
//     "<tbody class='addParameterAttr'><tr style='' class='addParameterAttrRow'><td><span class='addParameter' ><button class='attrButt addCommandButt'  onclick='addParameterAttr(this);return false;'><i class='fas fa-plus-square fs16 textbuttonicon'></i><span class='buttLabelWithIcon'>Add Attribute</span></button></span></td></tr></tbody>";
//   let parameterAttrRow =
//     "<table class='parameterAttrTable'><tr class='parameterAttrHeadLine' style='display: none;'><td  style='padding-left:5px;'>Attribute</td><td>Value</td><td></td>" +
//     column +
//     "<td></td><td class='fieldPlaceHolder'></td></tr><tbody class='parameterAttrBody'></tbody>" +
//     addButton +
//     "</table>";
//   $parametersContainer.html(parameterAttrRow);

//   let parameterAttrTable = $parametersContainer.find(".parameterAttrTable");

//   if (typeof parameters != "undefined") {
//     if (Object.keys(parameters).length > 0) {
//       var header = $parametersContainer.find(".parameterAttrHeadLine");
//       header.show();
//       $.each(parameters, function(index, value) {
//         parameterAttrTable.append(
//           constructParameterView(parameterAttrTable, index, value)
//         );
//       });
//     } else {
//       //Fresh
//     }
//   }
// }

// function constructParameterView($parameterAttrTable, index, attrValue) {
//   let source = "";

//   let $fieldsObject = $parameterAttrTable.closest(".fields");
//   if (typeof attrValue.source != "undefined") {
//     source = attrValue.source;
//   } else {
//     source = $fieldsObject.data("attrSource");
//   }
//   let removeButton = "";
//   // if (typeof removeButton=="undefined"){
//   if (!$fieldsObject.data("enableSuppress") || source == "t_testcases") {
//     removeButton =
//       "<a class='httpParaRemoveButton' href='javascript:void(0);' onclick='removeParameterAttr(this)' title='Delete Attribute'><i class='fas fa-trash fs16 iconRed' ></i></a>";
//   }
//   // }

//   let atRanId = "";

//   if (typeof attrValue.atRanId != "undefined") {
//     atRanId = attrValue.atRanId;
//   } else {
//     atRanId = getRandomInt(0, 100000);
//   }

//   let mandatorytd = getMandatory(attrValue, false, true);

//   //let readonly = "";
//   let disabled = "";

//   let suppressDesabled = "";

//   if ($fieldsObject.data("disableFixed")) {
//     if (attrValue.fix == 1) {
//       // readonly = 'readonly="readonly" style="color: #787878;"';
//       suppressDesabled = 'disabled="disabled"';
//     }
//     disabled = 'disabled="disabled"';
//   }

//   /* let $supprestd=getFixedSupress(attrValue, suppressDesabled, disabled,$fieldsObject.data( "enableSuppress"));
//  console.log($supprestd)*/

//   let $tr = $(
//     "<tr   data-atRanId='" +
//       atRanId +
//       "' source='" +
//       source +
//       "' style='' class='attributeRow parameterAttrRow singleCommandAttributes' data-attrRealName='parameterAttr'><td class=''><input type='text' placeholder='' class='httpParameterAttr required attrName' name='httpParameterAttr' value='" +
//       attrValue.name +
//       "' ></td><td class=''><input type='text' placeholder=' ' class='httpParameterVal  required' name='httpParameterVal'  ></td><td class='parameterAttrRemove ' style='padding-right:7px;'>" +
//       removeButton +
//       "</td><td class='httpSuppress  suppress'></td>" +
//       mandatorytd +
//       "<td class='fieldPlaceHolder'></td></tr>"
//   );
//   var id = $tr
//     .find(".httpParameterAttr")
//     .uniqueId()
//     .attr("id");
//   $tr.find(".httpParameterAttr").attr("name", id);
//   let $httpParameterVal = $tr.find(".httpParameterVal");
//   id = $httpParameterVal.uniqueId().attr("id");
//   $httpParameterVal.attr("name", id);
//   $httpParameterVal.val(attrValue.value);
//   $httpParameterVal.attr("originalValue", attrValue.value);
//   if ($fieldsObject.data("enableSuppress")) {
//     $tr
//       .find(".httpSuppress")
//       .html(
//         getSupressContent(
//           attrValue,
//           suppressDesabled,
//           disabled,
//           $fieldsObject.data("enableSuppress")
//         )
//       );
//   }
//   toggleHttpSuppress($tr);
//   return $tr;
// }

// export default {};

// function addHeaderVar(that, type) {
//   var removeButton =
//     "<a href='javascript:void(0);' onclick='removeHeaderVar(this)' title='Delete Variable'><i class='fas fa-trash fs16 iconRed' ></i></a>";
//   let $headerVarTable = $(that).closest(".headerVarTable");
//   let $evalRow;
//   if (type == "expect") {
//     let $caller = $(that);
//     let $fieldsObject = $caller.closest(".fields");
//     let operators = $fieldsObject.data("operators");
//     $evalRow = $(
//       constructHeaderViewExpectHttp("", "", removeButton, operators)
//     );
//   } else {
//     $evalRow = $(constructHeaderView("", "", removeButton));
//   }
//   $evalRow.appendTo($headerVarTable);
//   var header = $headerVarTable.find(".headerVarHeadLine");
//   header.show();
// }

// function constructHeaderViewExpectHttp(index, value, removeButton, operators) {
//   let evalResultOperator = getOperators("incNull", operators);
//   if (typeof removeButton == "undefined") {
//     removeButton =
//       "<a href='javascript:void(0);' onclick='removeHeaderVar(this)' title='Delete command'><i class='fas fa-trash fs16 iconRed' ></i></a>";
//   }
//   let content = "";
//   if (typeof value.content != "undefined") {
//     content = value.content;
//   }
//   let $tr = $(
//     "<tr style='' class='headerVarRow singleCommandAttributes' data-attrRealName='headerVar'><td class=''><input type='text' placeholder='' class='httpHeaderVar   required' name='httpHeaderVar' ></td><td class='evalResultOperatorExpectHttpContainer '>" +
//       makeSelector(
//         evalResultOperator,
//         value.operator,
//         "evalResultOperatorExpectHttp shortinput "
//       ) +
//       "</td><td class=''><input type='text' placeholder=' ' class='httpHeaderVal  required' name='httpHeaderVal'  ></td><td class='headerVarRemove '>" +
//       removeButton +
//       "</td><td class='fieldPlaceHolder'></td></tr>"
//   );
//   var id = $tr
//     .find(".httpHeaderVar")
//     .val(index)
//     .uniqueId()
//     .attr("id");
//   $tr.find(".httpHeaderVar").attr("name", id);
//   id = $tr
//     .find(".httpHeaderVal")
//     .val(content)
//     .uniqueId()
//     .attr("id");
//   $tr.find(".httpHeaderVal").attr("name", id);
//   return $tr;
// }

// function addParameterAttr(that) {
//   // var removeButton=  "<a href='javascript:void(0);' onclick='removeParameterAttr(this)' title='Delete Attribute'><i class='fas fa-trash fs16 iconRed' ></i></a>";
//   let $parameterAttrTable = $(that).closest(".parameterAttrTable");
//   let $fieldsObject = $parameterAttrTable.closest(".fields");
//   let mandatory = 0;
//   if ($fieldsObject.data("enableSuppress")) {
//     mandatory = 1;
//   }
//   let value = { mandatory: mandatory, value: "", name: "" };
//   var $evalRow = $(constructParameterView($parameterAttrTable, "", value)); //
//   $evalRow.appendTo($parameterAttrTable);
//   var header = $parameterAttrTable.find(".parameterAttrHeadLine");
//   header.show();
//   //resizeForm( $fieldsObject);
// }
// function getAttributeValueAdderContentParameterTab($parametersContainer, parameters) {
//   let column = "";
//   let $fieldsObject = $parametersContainer.closest(".fields");
//   if ($fieldsObject.data("enableSuppress")) {
//     column = '<td class="httpSuppress  suppress">Suppress</td>';
//   }
//   // var removeButton=  "<a href='javascript:void(0);' onclick='removeHeaderVar(this)' title='Delete command'><i class='fas fa-trash fs16 iconRed' ></i></a>";
//   var addButton = "<tbody class='addParameterAttr'><tr style='' class='addParameterAttrRow'><td><span class='addParameter' ><button class='attrButt addCommandButt'  onclick='addParameterAttr(this);return false;'><i class='fas fa-plus-square fs16 textbuttonicon'></i><span class='buttLabelWithIcon'>Add Attribute</span></button></span></td></tr></tbody>";
//   let parameterAttrRow = "<table class='parameterAttrTable'><tr class='parameterAttrHeadLine' style='display: none;'><td  style='padding-left:5px;'>Attribute</td><td>Value</td><td></td>" + column + "<td></td><td class='fieldPlaceHolder'></td></tr><tbody class='parameterAttrBody'></tbody>" + addButton + "</table>";
//   $parametersContainer.html(parameterAttrRow);

//   let parameterAttrTable = $parametersContainer.find(".parameterAttrTable");

//   if (typeof parameters != "undefined") {
//     if (Object.keys(parameters).length > 0) {
//       var header = $parametersContainer.find('.parameterAttrHeadLine');
//       header.show();
//       $.each(parameters, function (index, value) {
//         parameterAttrTable.append(constructParameterView(parameterAttrTable, index, value));
//       });
//     } else {  //Fresh

//     }
//   }
// }

// function constructParameterView($parameterAttrTable, index, attrValue) {
//   let source = "";

//   let $fieldsObject = $parameterAttrTable.closest(".fields");
//   if (typeof attrValue.source != 'undefined') {
//     source = attrValue.source;
//   } else {
//     source = $fieldsObject.data("attrSource");
//   }
//   let removeButton = "";
//   // if (typeof removeButton=="undefined"){
//   if (!$fieldsObject.data("enableSuppress") || source == 't_testcases') {
//     removeButton = "<a class='httpParaRemoveButton' href='javascript:void(0);' onclick='removeParameterAttr(this)' title='Delete Attribute'><i class='fas fa-trash fs16 iconRed' ></i></a>";
//   }
//   // }

//   let atRanId = "";

//   if (typeof attrValue.atRanId != 'undefined') {
//     atRanId = attrValue.atRanId;
//   } else {
//     atRanId = getRandomInt(0, 100000);
//   }

//   let mandatorytd = getMandatory(attrValue, false, true);

//   //let readonly = "";
//   let disabled = "";

//   let suppressDesabled = "";

//   if ($fieldsObject.data("disableFixed")) {
//     if (attrValue.fix == 1) {
//       // readonly = 'readonly="readonly" style="color: #787878;"';
//       suppressDesabled = 'disabled="disabled"';
//     }
//     disabled = 'disabled="disabled"';
//   }

//   /* let $supprestd=getFixedSupress(attrValue, suppressDesabled, disabled,$fieldsObject.data( "enableSuppress"));
//  console.log($supprestd)*/

//   let $tr = $("<tr   data-atRanId='" + atRanId + "' source='" + source + "' style='' class='attributeRow parameterAttrRow singleCommandAttributes' data-attrRealName='parameterAttr'><td class=''><input type='text' placeholder='' class='httpParameterAttr required attrName' name='httpParameterAttr' value='" + attrValue.name + "' ></td><td class=''><input type='text' placeholder=' ' class='httpParameterVal  required' name='httpParameterVal'  ></td><td class='parameterAttrRemove ' style='padding-right:7px;'>" + removeButton + "</td><td class='httpSuppress  suppress'></td>" + mandatorytd + "<td class='fieldPlaceHolder'></td></tr>");
//   var id = $tr.find(".httpParameterAttr").uniqueId().attr('id');
//   $tr.find(".httpParameterAttr").attr("name", id);
//   let $httpParameterVal = $tr.find(".httpParameterVal");
//   id = $httpParameterVal.uniqueId().attr('id');
//   $httpParameterVal.attr("name", id);
//   $httpParameterVal.val(attrValue.value);
//   $httpParameterVal.attr("originalValue", attrValue.value);
//   if ($fieldsObject.data("enableSuppress")) {
//     $tr.find(".httpSuppress").html(getSupressContent(attrValue, suppressDesabled, disabled, $fieldsObject.data("enableSuppress")));
//   }
//   toggleHttpSuppress($tr);
//   return $tr;
// }
</script>