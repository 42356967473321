<template>
  <div>
    <v-row v-if="localStoredStep" class="inlineDiv decodeAsName">
      <v-col class="inlineDiv">
        <associatedOption
            :fieldAttributes="{ name: 'Decode as', associatedOption: decodeTypes, }"
            type="select"
            :fieldAttrInput="{}"
            field="DecodeAs"
            v-model="DecodeAs"
            :templateContent="result"></associatedOption>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import associatedOption from '@/commonComponents/associatedOption.vue';

export default {
  props: ['localStoredStep', 'result', 'isValid'],
  data: function () {
    return {
      decodeTypes: [
        ['JSON', 'JSON'],
        ['text', 'Text'],
        ['XML', 'XML'],
      ],
      checkValue: {},
    };
  },
  components: {
    associatedOption,
  },
  computed: {
    DecodeAs: {
      get() {
        let DecodeAs = 'JSON';
        if (this.localStoredStep && this.localStoredStep.check['DecodeAs'] !== undefined) {
          DecodeAs = this.localStoredStep.check['DecodeAs'];
        }
        return DecodeAs;
      },
      set(newVal) {
        if (this.localStoredStep) {
          this.$set(this.localStoredStep['check'], 'DecodeAs', newVal);
          this.$emit('stored-value-changed', this.localStoredStep);
        }
      },
    },
  },
  methods: {
    updateResponseKey(index, newVal) {
      this.$set(this.localStoredStep['check'], index, newVal);
      this.$emit('stored-value-changed', this.localStoredStep);
    },
  },
};
</script>