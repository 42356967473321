<template>
  <v-icon
    :size="size"
    @click.stop="$emit('click', 'insertnext')"
    title="Insert step into very next"
    :disabled="disabled"
    >mdi-plus-box-multiple</v-icon
  >
</template>
<script>
export default {
  computed: {
    disabled() {
      let disabled = false;
      // console.log(this.result);
      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },
  },
  props: {
    size: Number,
  },
};
</script>


