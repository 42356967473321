<template>
  <div>
    <configContainer title="Condition">
      <template v-slot:content>
        <buildFieldsRows
          :templateContent="httpConditionHeaderConfig"
          v-model="check"
          languageIndex="expectStep"
        ></buildFieldsRows>
        <HTTPTabs
          :packetType="stepType"
          :requestType="check.method"
          v-on="$listeners"
          result="result"
          :stepStoredValue="stepStoredValue"
          :isValid="isValid"
        >
          <template v-slot:tabsContent="data">
            <evalContentTable
              :value="check[data.item]"
              @input="updateCheck(data.item, ...arguments)"
              type="expect"
              operatorType="incNull"
              :correctOperators="true"
              :isValid="isValid"
              :forWhat="decodeType"
            ></evalContentTable>
          </template>
        </HTTPTabs>
      </template>
    </configContainer>
    <subStepsModule
      :groupedStepDependentInfo="groupedStepDependentInfo"
      :stepStoredValue="localStoredValue"
      :stepID="stepID"
      :result="result"
      @group-value-changed="updateTestValue(...arguments)"
      :isValid="isValid"
    ></subStepsModule>

    <localDataGetter
      :stepStoredValue="response.parameters"
      :result="result"
      :ipcanStepTemplates="ipcanStepTemplates"
      :stepType="stepType"
      application="HTTP"
    >
      <template v-slot:default="data">
        <expectHTTPResponse
          v-if="data.localData != null"
          :localData="data.localData"
          :stepStoredValue="stepStoredValue"
          :result="result"
          :stepType="stepType"
          v-on="$listeners"
          :isValid="isValid"
          :stepID="stepID"
        ></expectHTTPResponse>
      </template>
    </localDataGetter>
  </div>
</template>
<script>
import expectHTTPResponse from "@/components/legacy/expectHTTPResponse.vue";
import subStepsModule from "@/components/legacy/subStepsModule.vue";

import evalContentTable from "@/components/legacy/evalContentTable.vue";
import localDataGetter from "@/components/legacy/localDataGetter.vue";
import HTTPTabs from "@/components/legacy/HTTPTabs.vue";
import configContainer from "@/commonComponents/configContainer.vue";
import buildFieldsRows from "@/commonComponents/buildFieldsRows.vue";
export default {
  data() {
    return {
      httpConditionHeaderConfig: {
        fields: {
          method: {
            type: "select",
            options: ["GET", "POST", "PUT", "DELETE", "PATCH"],
            fieldAttrInput: { class: "required" },
          },
          uri: {
            type: "text",
            fieldAttrInput: { class: "required" },
          },
        },
      },
    };
  },
  components: {
    subStepsModule,

    evalContentTable,
    localDataGetter,

    expectHTTPResponse,
    configContainer,
    HTTPTabs,
    buildFieldsRows,
  },
  props: [
    "groupedStepDependentInfo",
    "stepStoredValue",
    "result",
    "stepID",
    "stepType",
    "ipcanStepTemplates",
    "isValid",
  ],

  computed: {
    decodeType(){
      let decodeType= "";
      if(typeof this.stepStoredValue.check!="undefined" && typeof this.stepStoredValue.check.DecodeAs!="undefined"){
          decodeType = this.stepStoredValue.check.DecodeAs;
      }
      return decodeType;
    },
    response() {
      let response = {};
      if (typeof this.stepStoredValue.response != "undefined") {
        response = this.stepStoredValue.response;
      }
      return response;
    },
    check: {
      get() {
        let check = {};
        if (typeof this.stepStoredValue.check != "undefined") {
          check = this.stepStoredValue.check;
        }
        return check;
      },
      set(newValue) {
        this.$set(this.localStoredValue, "check", newValue);
        this.$emit("stored-value-changed", this.localStoredValue);
      },
    },
    localStoredValue: {
      get() {
        return this.stepStoredValue;
      },
      set(newValue) {
        console.log("new value");
        this.$emit("stored-value-changed", newValue);
      },
    },
    localEval: {
      get() {
        return this.localStoredValue.check.eval;
      },
      set(newVal) {
        this.$set(this.localStoredValue.check, "eval", newVal);
        this.$emit("stored-value-changed", this.localStoredValue);
      },
    },
  },
  methods: {
    updateCheck(index, value) {
      this.$set(this.localStoredValue.check, index, value);
      this.$emit("stored-value-changed", this.localStoredValue);
    },
    updateTestValue(substepsValue) {
      let local = JSON.parse(JSON.stringify(substepsValue));
      console.log(local);
      this.$set(this.localStoredValue, "substeps", local);
      this.$emit("stored-value-changed", this.localStoredValue);
    },
  },
};

// function renderExpectHttpAttribute(
//   $stepTypeSelector,
//   fieldsObject,
//   stepType,
//   stepStoredValue,
//   stepID,
//   additionalStepsInfo,
//   packetType
// ) {
//   let $requestTable = $stepTypeSelector.closest(".requestTable");
//   let $step = $stepTypeSelector.closest(".step");
//   var $additionalFields = $requestTable.find(".additionalFields");

//   /* if (window.templates[stepType]) {
//       let attrArray= jQuery.extend(true, {}, window.templates[stepType]);
//       let parameters={};
//       if (typeof stepStoredValue["parameters"]!="undefined"){
//         parameters=stepStoredValue["parameters"];
//       }
//       if(parameters["expectType"]=="fg"){
//         delete attrArray["reuse"];
//         // render timeout
//         let $onActionContainer = $requestTable.find(".onActionContainer");
//         $onActionContainer.html("");
//         let onTimeout={};
//         if (typeof stepStoredValue["OnTimeout"]!="undefined"){
//           onTimeout=stepStoredValue["OnTimeout"];
//         }
//         addOnTimeOutNoResult($onActionContainer, "On Timeout", "onTimeoutContainer", onTimeout);
//       }else{
//         delete attrArray["wait"];
//       }
//     }*/

//   let requestAttributeId = "RequestAttributes" + stepID;
//   let packetResponseType = "HTTP_RESPONSE";
//   $requestTable
//     .find(".objectAreaBody")
//     .html(
//       "<tr><td colspan=3 class='objectArea'><div class='objectContainer'><div class='checkContainer responseType'></div><div class='subStepHolder' ></div><div class='ipcanStep application treenode' name='" +
//         packetResponseType +
//         "' data-application-type='" +
//         packetResponseType +
//         "' data-node-type='packetTemplate'><table class='templateTable'><tr><td colspan=3>" +
//         getDiameterNewHeader("Response Packet") +
//         "<div class='RequestAttrs tabs attributegroup ui-widget-content ui-corner-bottom expectRequestGroup' id='" +
//         requestAttributeId +
//         "'></div></td></tr></table></div></div><div class='onActionContainer'></div></div></td></tr>"
//     );
//   let $requestAttrsContainer = $requestTable.find(".RequestAttrs");

//   let $templatesContainer = $(
//     "<div class='packetResponseInfoContainer normaltable connectionContainer'><div class='lineDiv'><div class='inlineBlock stepTypeNameContainer' >Packet</div><div class='packetValue inlineBlock' ></div></div><div class='statusCodeContainer'></div></div>"
//   ).appendTo($requestAttrsContainer);
//   let $diameterRequestContainer = $(
//     "<div class='  attributesContainer'></div>"
//   ).appendTo($requestAttrsContainer);
//   let $checkContainer = $requestTable.find(".checkContainer");

//   let $httpSource = $requestTable.find(".checkContainer");
//   let $httpConditionTable = $(
//     '<div class=" evalContentContainer ui-widget-content ui-corner-bottom"><div class="RequestAttrs tabs"><div class="ui-widget-header diameterNewResponseHeader"><div class="inlineDiv">Condition</div></div><div class="httpConditionContent pad5"></div></div></div>'
//   ).appendTo($httpSource);
//   let $httpConditionContent = $httpSource.find(".httpConditionContent");

//   let uri = "",
//     methodVal = "";
//   if (
//     typeof stepStoredValue.check != "undefined" &&
//     typeof stepStoredValue.check.uri != "undefined"
//   ) {
//     uri = stepStoredValue.check.uri;
//   }
//   if (
//     typeof stepStoredValue.check != "undefined" &&
//     typeof stepStoredValue.check.method != "undefined"
//   ) {
//     methodVal = stepStoredValue.check.method;
//   }

//   let $mainAttributesContainer = $(
//     "<div class='mainAttributesContainer'></div>"
//   ).appendTo($httpConditionContent);
//   let $headerAttributesContainer = $(
//     "<div class='headerAttributesContainer pL5pT7'></div>"
//   ).appendTo($mainAttributesContainer);

//   let methods = ["GET", "POST", "PUT", "DELETE", "PATCH"];
//   methods.sort();
//   let $methodLine = $(
//     "<div class='methodLine'><div class='inlineDiv httpNamesContainer'>Method*</div><div class='inlineDiv methodSelectorContainer fieldsAttributesContainer'></div></div>"
//   ).appendTo($headerAttributesContainer);
//   $methodLine
//     .find(".methodSelectorContainer")
//     .append(
//       makeSelector(methods, methodVal, {
//         class: "methodSelector required",
//         onChange: "showTab(this)",
//       })
//     );
//   let $uriLine = $(
//     "<div class='uriLine'><div class='inlineDiv httpNamesContainer'>URI contains*</div><div class='inlineDiv fieldsAttributesContainer'><input type='text' value='" +
//       uri +
//       "' name='URI' class='URIinput required'/></div></div>"
//   ).appendTo($headerAttributesContainer);
//   let $tabContainer = $("<div class='httpTabs'></div>").appendTo(
//     $mainAttributesContainer
//   );
//   buildHttpTabs($tabContainer, stepStoredValue, packetType, "expect");

//   //SUB STEP
//   subStepsModule(
//     $requestTable,
//     stepStoredValue,
//     stepID,
//     fieldsObject,
//     packetType
//   );
//   if (typeof stepStoredValue.check == "undefined") {
//     stepStoredValue.check = {};
//   }
//   let evalValues = stepStoredValue.check.eval;
//   if (typeof stepStoredValue.req == "undefined") {
//     stepStoredValue.req = {};
//   }

//   let templateType = "HTTP";
//   let subType = $step.attr("data-subtype");
//   let category = $step.attr("data-category");
//   var $packeTemplateSelector = $(
//     "<select class='packetSelector  required' name='packet' ><select> <a href='javascript:void(0);' onclick='AddFieldUpdateSelect(this,&#39;serve.php?f=configuration&amp;f2=nocsPacketTemplates&amp;p=" +
//       category +
//       "&amp;c_subType=" +
//       subType +
//       "&#39; ,{c_type:&#39;" +
//       templateType +
//       "&#39;},{width:1400});'> <i class='fas fa-plus-square fs16'></i></a>"
//   ).appendTo($templatesContainer.find(".packetValue"));
//   let stepValue = {};
//   stepValue.localClientId = "";
//   stepValue.remoteServerId = "";
//   stepValue.templateId = "";
//   if (
//     typeof stepStoredValue.response !== "undefined" &&
//     typeof stepStoredValue.response.parameters !== "undefined" &&
//     typeof stepStoredValue.response.parameters.templateId !== "undefined"
//   ) {
//     stepValue.templateId = stepStoredValue.response.parameters.templateId;
//   }
//   //should be http Templae include
//   setConnectionsValues(
//     packetType,
//     fieldsObject,
//     stepID,
//     templateType,
//     $step,
//     $templatesContainer,
//     stepValue
//   );
// }
</script>