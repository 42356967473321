var render = function render(){var _vm=this,_c=_vm._self._c;return (typeof _vm.value.content == 'object')?_c('div',{class:_vm.treeClass(_vm.value.content)},_vm._l((_vm.value.content),function(line,index,i){return _c('div',{key:index},[_c('v-row',{class:_vm.treeClass(line),attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"ml-3 mt-2"},[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.treeLength > 1)?_c('v-col',{staticClass:"d-inline-block treebg",style:('max-width:' + _vm.treeLength * 15 + 'px')},[_c('span',[_vm._v("|")]),_vm._l((_vm.treeLength),function(line){return _c('span',{key:line},[_vm._v("__")])})],2):_vm._e(),_c('v-col',[(_vm.value.type != 'array')?_c('primaryTextfield',{attrs:{"value":index,"fieldAttrInput":{
              id:
                'httpBodyJSON_' +
                _vm.treeLength +
                '_' +
                _vm.getIdIndex(index, i) +
                '_attribute',
            },"label":"","templateContent":_vm.result},on:{"change":function($event){return _vm.updateIndex(index, ...arguments)}}}):_vm._e()],1)],1)],1),_c('v-col',{staticStyle:{"max-width":"40px","min-width":"40px"}},[(line.type == 'array' || line.type == 'object')?_c('div',{staticClass:"ml-2 mt-4"},[_c('v-icon',{attrs:{"title":"Add","id":'httpBodyJSON_' +
            _vm.treeLength +
            '_' +
            _vm.getIdIndex(index, i) +
            '_add'},on:{"click":function($event){return _vm.addLine(index)}}},[_vm._v("mdi-plus")])],1):_vm._e()]),_c('v-col',{staticClass:"mt-3"},[_c('associatedOption',{attrs:{"fieldAttributes":{
          name: '',
          associatedOption: _vm.types,
        },"type":"select","fieldAttrInput":{
          class: 'required',
          id:
            'httpBodyJSON_' +
            _vm.treeLength +
            '_' +
            _vm.getIdIndex(index, i) +
            '_type',
        },"templateContent":_vm.result},on:{"input":function($event){return _vm.changeContentType(index, ...arguments)}},model:{value:(line.type),callback:function ($$v) {_vm.$set(line, "type", $$v)},expression:"line.type"}})],1),_c('v-col',{staticClass:"ml-2 mt-3"},[_c('jsonInputValue',{attrs:{"result":_vm.result,"type":line.type,"id":'httpBodyJSON_' +
          _vm.treeLength +
          '_' +
          _vm.getIdIndex(index, i) +
          '_content'},model:{value:(line.content),callback:function ($$v) {_vm.$set(line, "content", $$v)},expression:"line.content"}})],1),_c('v-col',{staticClass:"ml-2 mt-3",staticStyle:{"max-width":"40px"}},[_c('trash',{attrs:{"id":'httpBodyJSON_' +
          _vm.treeLength +
          '_' +
          _vm.getIdIndex(index, i) +
          '_trash'},on:{"click":function($event){return _vm.removeLine(index)}}})],1)],1),_c('jsonContentRecursive',{attrs:{"value":line,"result":_vm.result,"treeLength":_vm.treeLength + 1}})],1)}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }