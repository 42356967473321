<template>
  <v-toolbar color="primary" dark flat height="50">
    <v-toolbar-title class="subtitle-2">{{ title }}</v-toolbar-title>
    <slot></slot>
  </v-toolbar>
</template>
<script>
export default {
  props: ["title"],
};
</script>