<template>
  <div>
    <v-expansion-panels hover tile focusable :value="tab" multiple>
      <!-- because of draggable. it is shrinking -->
      <draggable
        v-model="localStoredBody"
        @start="drag = true"
        @end="drag = false"
        handle=".handle"
        style="width: inherit"
      >
        <httpPart
          v-for="(value, index) in localStoredBody"
          :key="index"
          :singlePartValue="value"
          :index="index"
          @input="updatePart(index, ...arguments)"
          @trash-clicked="removePart(index, ...arguments)"
          :isValid="isValid"
          @changeIsValid="$emit('changeIsValid', $event)"
          :linePrefix="linePrefix + '_part_' + index"
          :draggable="draggable"
        ></httpPart>
      </draggable>
    </v-expansion-panels>

    <!-- <div class="addHeaderVar">
      <div class="addHeaderVarRow">
        <td>
          <span class="addHeader">
            <button class="attrButt addCommandButt" onclick="addHttpPartButton(this);return false;">
              <i class="fas fa-plus-square fs16 textbuttonicon"></i>
              <span class="buttLabelWithIcon">Add Part</span>
            </button>
          </span>
        </td>
      </div>
    </div>-->
    <v-row>
      <v-col>
        <primaryButton
          type="button"
          @click="addPart()"
          label="Add Part"
          icon="mdi-plus"
          color="primary"
          :attributesArray="{ id: linePrefix + '_AddPart' }"
        ></primaryButton>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import httpPart from "@/components/legacy/httpPart.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
import draggable from "vuedraggable";
export default {
  data() {
    return { tab: [] };
  },
  props: ["storedBody", "isValid", "linePrefix", "draggable"],
  components: {
    httpPart,
    draggable,
    primaryButton,
  },
  computed: {
    localStoredBody: {
      get() {
        return this.storedBody;
      },
      set(newVal) {
        this.updatebody(newVal);
      },
    },
  },
  methods: {
    updatebody(newVal) {
      this.$emit("stored-body-changed", newVal);
    },
    addPart() {
      this.localStoredBody.push({
        header: {},
        parameters: { "content-type": "JSON" },
        body: {},
      });
      //   this.$emit("group-value-changed", this.convertToStepObject(local));
      this.tab.push(this.localStoredBody.length - 1);
    },
    removePart(index) {
      console.log("removePart");
      this.localStoredBody.splice(index, 1);
      this.$emit("stored-body-changed", this.localStoredBody);
    },
    updatePart(index, newValue) {
      console.log("update part", index, newValue);
      this.$set(this.localStoredBody, index, newValue);
      this.$emit("stored-body-changed", this.localStoredBody);
    },
  },
};
</script>