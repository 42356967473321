<template>
<div>
  <diameterRequestAttr
          :attrsArray="result['additional']['diameterAttrs']"
          :value="mergedTemplate.req"
          :application="stepType"
          source="t_testcases"
          :radiusDynamicPars="radiusDynamicPars"
          @input="updateReq(...arguments)"
          :result="result"
          :isValid="isValid"
          :linePrefix="stepType + '_' + stepID + '_request'"
          :colorsConfig="colorsConfig"
  ></diameterRequestAttr>
</div>
</template>
<script>
import diameterRequestAttr from "@/components/legacy/diameterRequestAttr.vue";
import { getIndexedTemplate } from "@/js/testHelpers.js";
export default {
  components: { diameterRequestAttr },
  props: [
    "stepStoredValue",
    "result",
    "mergedTemplate",
    "stepType",
    "stepID",
    "radiusDynamicPars",
    "config",
    "templatesData",
    "isValid",
  ],
  data() {
    return {
      colorsConfig: {
        t_localClients: "red",

        t_remoteServers: "blue",

        t_templates: "green",
      },
    };
  },
  methods: {
    getOriginalValue(line, index, key, valueArray, objectReq) {
      console.log(line.tree);
      let originalValue = line.content;

      let mapping = {};
      let i = 0;
      valueArray.forEach((lineinfo, innerIndex) => {
        if (lineinfo.source == line.source) {
          mapping[innerIndex] = i;
          i++;
        }
      });
      if (
        typeof objectReq[key] != "undefined" &&
        typeof objectReq[key][mapping[index]] != "undefined"
      ) {
        originalValue = objectReq[key][mapping[index]].content;
      }

      return originalValue;
    },
    updateReqContent(valueArray, request, line, index, key, originalValue) {
      // if (line.source == "t_testcases") {
      //   if (typeof request[key] != "undefined") {
      //     request[key].push(line);
      //   } else {
      //     request[key] = [line];
      //   }
      // } else {

      if (typeof line.content == "string") {
        let value = line.content;

        //console.log(originalValue);
        if (typeof originalValue == "undefined") {
          originalValue = "";
        }

        if (typeof value == "undefined" || value == null) {
          value = "";
        }
        var source = line.source;
        // //////////console.log(value);
        let attrSource = "t_testcases";
        if (
          line.fix == "2" ||
          originalValue !== value ||
          originalValue === "" ||
          source == attrSource
        ) {
          let templateFixed = "0";
          let testFixed = "0";
          // added condition originalValue!=="" for the inputs which are new
          if (line.fix == "2") {
            templateFixed = "2";
            testFixed = "2";
          }
          if (originalValue !== value && originalValue !== "") {
            templateFixed = "2";
            testFixed = "0";
          }
          let mandatory = 0;
          if (value.mandatory == 1) {
            mandatory = 1;
          }

          if (
            originalValue !== value ||
            (originalValue === value && source == "t_testcases")
          ) {
            /// added 29.04.2016 by ammar bug when the origional value is a EVAL which does not exsist in the options
            //  if (!($attrValueObject.prop("tagName")=="SELECT" && originalValue.indexOf("EVAL:")==0 && value.indexOf("EVAL:")==-1)){
            ////console.log(originalValue,value,source);
            let attributeInfo = {
              content: value,
              fix: testFixed,
              source: attrSource,
              mandatory: mandatory,
              atRanId: line.atRanId,
            };

            if (typeof request[key] != "undefined") {
              request[key].push(attributeInfo);
            } else {
              request[key] = [attributeInfo];
            }
          }

          // var attributeName = value.attribute;
          if (originalValue !== "" && source != "t_testcases") {
            // adding the original value from templates
            let attributeInfo = {
              content: originalValue,
              fix: templateFixed,
              source: source,
              mandatory: mandatory,
              atRanId: line.atRanId,
            };

            if (typeof request[key] != "undefined") {
              request[key].push(attributeInfo);
            } else {
              request[key] = [attributeInfo];
            }
          }
        }
      } else {
        let innerRequest = {};
        // line.content.forEach((innerContent, innerIndex) => {
        //   this.updateReqContent(innerContent, innerRequest, line, index, key);
        //   line.content = innerRequest;
        // });

        for (const [innerKey, innerValueArray] of Object.entries(
          line.content
        )) {
          innerValueArray.forEach((innerLine, innerIndex) => {
            let innerOriginalValue = this.getOriginalValue(
              innerLine,
              innerIndex,
              innerKey,
              innerValueArray,
              originalValue
            );
            this.updateReqContent(
              innerValueArray,
              innerRequest,
              innerLine,
              innerIndex,
              innerKey,
              innerOriginalValue
            );
          });
        }
        console.log(innerRequest);
        if (Object.keys(innerRequest).length > 0) {
          line.content = innerRequest;
          line.fix = 0;

          if (typeof request[key] != "undefined") {
            request[key].push(line);
          } else {
            request[key] = [line];
          }
        }
      }
    },
    updateReq(reqValue) {
      console.log(reqValue);
      let request = {};
      for (const [key, valueArray] of Object.entries(reqValue)) {
        valueArray.forEach((line, index) => {
          let objectReq = {};
          if (typeof this.config[line.source] != "undefined") {
            let config = this.config[line.source];

            let objectTemplate = getIndexedTemplate(
              config.type,
              this.stepType,
              this.templatesData
            );
            objectReq = objectTemplate.req;
          }

          let originalValue = this.getOriginalValue(
            line,
            index,
            key,
            valueArray,
            objectReq
          );
          // if (typeof line.content == "string") {
          this.updateReqContent(
            valueArray,
            request,
            line,
            index,
            key,
            originalValue
          );
          // }else {

          // }
          //  }
        });
      }
      // console.log(JSON.stringify(request));

      this.$emit("input", request);
    },
  },
};
</script>