<template>
  <div>
    <configContainer title="Response">
      <template v-slot:content>
        <div v-if="localData != null">
          <step_connectionTemplateBlockInbound
            :connectionOption="false"
            :templateOption="localData.options.templateId"
            :templateValue="templateId"
            :localData="localData"
            @value-changed="updateTemplate"
            :addUrlAttr="{
              subType: 'INHTTP',
              category: 'Protocols',
              stepType: 'HTTP_RESPONSE',
            }"
            :dialogRef="result.dialogRef"
            :result="result"
          ></step_connectionTemplateBlockInbound>
          <HttpStepRequest
            :mergedTemplate="mergedTemplate"
            :stepStoredValue="response"
            :originalTemplate="originalTemplate"
            :packetType="packetType"
            :stepType="stepType"
            :stepID="stepID"
            @header-changed="updateRequestKey('header', ...arguments)"
            @body-changed="updateRequestKey('body', ...arguments)"
            @parameter-changed="updateRequestKey('parameters', ...arguments)"
            :isValid="isValid"
            v-on="$listeners"
          ></HttpStepRequest>
        </div>
      </template>
    </configContainer>
  </div>
</template>
<script>
import step_connectionTemplateBlockInbound from "@/components/legacy/step_connectionTemplateBlockInbound.vue";
import HttpStepRequest from "@/components/legacy/HttpStepRequest.vue";
import configContainer from "@/commonComponents/configContainer.vue";

import { buildHTTPStoredValuesTemplate } from "@/js/testStepsHelpers/commonHttp.js";
export default {
  props: [
    "localData",
    "stepStoredValue",
    "result",
    "stepType",
    "stepID",
    "isValid",
  ],
  components: {
    configContainer,
    HttpStepRequest,
    step_connectionTemplateBlockInbound,
  },
  data() {
    return {
      packetType: "HTTP_RESPONSE",
    };
  },

  computed: {
    response() {
      let response = {};
      if (typeof this.stepStoredValue.response != "undefined") {
        response = this.stepStoredValue.response;
      }
      return response;
    },
    mergedTemplate() {
      console.log("recalculating");
      // console.log("stepStoredValue", JSON.stringify(this.stepStoredValue));
      let mergedTemplate = {};
      if (this.originalTemplate != null) {
        mergedTemplate = buildHTTPStoredValuesTemplate(
          this.originalTemplate,
          this.stepStoredValue.response,
          this.stepType
        );
      }
      //console.log("mergedTemplate", JSON.stringify(mergedTemplate));
      return mergedTemplate;
    },
    originalTemplate() {
      let originalTemplate = {};
      if (typeof this.templatesData.packet != "undefined") {
        originalTemplate = JSON.parse(this.templatesData.packet)[
          this.packetType
        ];
      }
      return originalTemplate;
    },
    templatesData() {
      let templatesData = {};
      if (this.localData != null) {
        templatesData = this.localData.templates;
      }
      return templatesData;
    },
    templateId() {
      let templateId = "";
      if (
        typeof this.response != "undefined" &&
        typeof this.response.parameters != "undefined" &&
        typeof this.response.parameters.templateId != "undefined"
      ) {
        templateId = this.response.parameters.templateId;
      }
      return templateId;
    },
  },
  methods: {
    updateRequestKey(index, newVal) {
      let localStoredStep = { ...this.stepStoredValue };
      console.log("update request", localStoredStep);
      if (typeof localStoredStep.response["req"] == "undefined") {
        this.$set(localStoredStep.response, "req", {});
      }
      this.$set(localStoredStep.response["req"], index, newVal);
      this.$emit("stored-value-changed", localStoredStep);
    },
    updateRequest(request) {
      let localStoredStep = { ...this.stepStoredValue };
      console.log("diameter response", request);
      this.$set(localStoredStep.response, "req", request);
      this.$emit("stored-value-changed", localStoredStep);
    },
    updateTemplate(field, value) {
      console.log("update tempate called", value);
      let localStoredStep = { ...this.stepStoredValue };
      if (localStoredStep == null) {
        localStoredStep = {};
      }

      if (
        typeof localStoredStep.response != "undefined" &&
        typeof localStoredStep.response.parameters != "undefined" &&
        typeof localStoredStep.response.parameters.templateId != "undefined" &&
        localStoredStep.response.parameters.templateId != value
      ) {
        //template id is changed. reset request body
        this.$set(localStoredStep.response, "req", {});
      }
      this.$set(localStoredStep.response.parameters, field, value);
      this.$emit("stored-value-changed", localStoredStep);
    },
  },
};
</script>