<template>
  <v-expansion-panel hover tile focusable multiple dense>
    <v-expansion-panel-header
      class="stepHeadColor subtitle-2"
      color="white--black"
    >
      <v-row no-gutters>
        <v-icon v-if="draggable" class="handle">mdi-drag-vertical</v-icon>
        <div class="mt-1">
          {{ language.componentLabel.labelPart }} {{ index + 1 }}
          {{ language.componentLabel.labelCType }}:
          {{ contentType.charAt(0).toUpperCase() + contentType.slice(1) }}
        </div>
        <v-spacer></v-spacer>
        <v-col style="max-width: 40px">
          <trash :size="20" v-on.stop="$listeners"></trash>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-container style="max-width: none !important">
        <div class="singlePartContainer">
          <tabsStructure
            :tabNames="tabNames"
            @changeIsValid="$emit('changeIsValid', $event)"
            :tabsPrefix="linePrefix"
          >
            <template v-slot:tabsContent="data">
              <div v-if="data.item.index == 'Header'">
                <attributeValueAdderContent
                  :value="singlePartValue['header']"
                  :headerTitle="true"
                  @input="updateRequestIndex('header', ...arguments)"
                  :indexName="language.HTTP_step.fieldName"
                  :isValid="isValid"
                  :linePrefix="linePrefix + '_header'"
                  :hideDetails="true"
                ></attributeValueAdderContent>
              </div>
              <div v-if="data.item.index == 'Body'">
                <HTTPBodyContent
                  :contentType="contentType"
                  packetType="multipart"
                  :storedBody="storedBody"
                  @stored-body-changed="
                    updateRequestIndex('body', ...arguments)
                  "
                  @content-type-changed="updateContentType"
                  :isValid="isValid"
                  :hideDetails="true"
                  :draggable="draggable"
                ></HTTPBodyContent>
              </div>
            </template>
          </tabsStructure>
        </div>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import tabsStructure from "@/components/legacy/tabsStructure.vue";
import attributeValueAdderContent from "@/components/legacy/attributeValueAdderContent.vue";

import { getRandomInt } from "@/js/helper.js";
import trash from "@/commonComponents/dynamic/trash.vue";
export default {
  data() {
    return {
      tabNames: [
        { index: "Header", name: "Header", disabled: false },
        { index: "Body", name: "Body", disabled: false },
      ],
    };
  },
  components: {
    tabsStructure,
    attributeValueAdderContent,
    trash,
    HTTPBodyContent: () => import("@/components/legacy/HTTPBodyContent.vue"),
  },
  created() {
    let atRanId = "";
    if (typeof this.singlePartValue.atRanId != "undefined") {
      atRanId = this.singlePartValue.atRanId;
    } else {
      atRanId = getRandomInt(0, 100000);
      let localStoredStep = { ...this.singlePartValue };
      this.$set(localStoredStep, "atRanId", atRanId);
      this.$emit("input", localStoredStep);
    }
  },
  computed: {
    storedBody() {
      let storedBody = {};
      if (typeof this.singlePartValue["body"] != "undefined") {
        storedBody = this.singlePartValue["body"];
      }
      return storedBody;
    },
    contentType() {
      let contentType = "JSON";

      if (
        typeof this.singlePartValue.parameters != "undefined" &&
        typeof this.singlePartValue.parameters["content-type"] != "undefined"
      ) {
        contentType = this.singlePartValue.parameters["content-type"];
      }
      return contentType;
    },
  },
  methods: {
    checkClicked(value, index) {
      console.log("checkClicked", value, index);
      if (value == "trash") {
        this.$emit("remove-part");
      }
    },
    updateRequestIndex(index, newval) {
      console.log("http part", index, newval);
      let localStoredStep = { ...this.singlePartValue };
      this.$set(localStoredStep, index, newval);
      this.$emit("input", localStoredStep);
    },

    updateContentType(contentType) {
      let localStoredStep = { ...this.singlePartValue };
      this.$set(localStoredStep["parameters"], "content-type", contentType);
      this.$emit("input", localStoredStep);
    },
  },
  props: {
    singlePartValue: {},
    index: {},
    isValid: {},
    linePrefix: {},
    draggable: { type: Boolean, default: true },
  },
};
</script>