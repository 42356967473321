<template>
  <div>
    <v-card class="mb-6 mt-3" outlined>
      <v-list-item-title class="pa-2 subtitle-2">{{
        onEvent
      }}</v-list-item-title>
      <v-container class="d-flex">
        <lineFieldsContainer
          :fieldAttrRow="{}"
          :fields="fields"
          :valueLine="valueLine"
          @field-line-changed="checkEventValue(...arguments)"
          :templateContent="result"
        ></lineFieldsContainer>
        <HelpButton id="onTimeout_block"></HelpButton>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import options from "@/cfg/options.json";
import lineFieldsContainer from "@/commonComponents/lineFieldsContainer.vue";
import HelpButton from "../basic/HelpButton.vue";
export default {
  components: {HelpButton, lineFieldsContainer },
  data() {
    return {
      retry: [...Array(10).keys()],
      //retry: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
    };
  },
  props: {
    resultArray: {
      type: Array,
      default: function () {
        return options.onTimeoutResult;
      },
    },
    onEvent: {},
    value: {},
    result: {},
  },
  computed: {
    valueLine() {
      if (
        typeof this.value != "undefined" &&
        Object.keys(this.value).length != 0
      ) {
        return this.value;
      } else {
        let line = { retry: "0", action: "stopTest", result: "runtime_error" }; //here is default,no idea why this creepy!
        this.$emit("input", line);
        return line;
      }
    },

    fields() {
      let fields = {
        retryString: {
          colAttrs: { style: "max-width:40px", class: "" },
          type: "string",
          string: "Retry",
        },
        retry: {
          type: "select",
          associatedOption: this.retry,
          fieldAttrInput: { class: "required" },
          name: "",
        },
        lastRetry: {
          colAttrs: { style: "max-width:140px", class: "" },
          type: "string",

          string: "times. On last try, if",
        },
        result: {
          type: "select",
          associatedOption: this.resultArray,
          name: "",
          default: "runtime_error",
          fieldAttrInput: { class: "required" },
        },
        then: {
          colAttrs: { style: "max-width:30px", class: "" },
          type: "string",
          string: "then",
        },
        action: {
          type: "select",
          associatedOption: options.evalAction,
          name: "Action",
          default: "stopTest",
          fieldAttrInput: { class: "required" },
        },
      };
      return fields;
    },
  },
  methods: {
    checkEventValue(line) {
      this.$emit("input", line);
    },
  },
};
</script>