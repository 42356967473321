<template>
  <v-expansion-panel hover tile focusable multiple dense>
    <v-expansion-panel-header
      class="sortableHeader accordionHeader stepHeadColor"
      :id="stepType  + '_' + stepID + '_stepHeader'"
    >
      <stepHeader
        :result="result"
        :stepType="stepType"
        :stepID="stepID"
        :additionalStepsInfo="additionalStepsInfo"
        :stepStoredValue="stepStoredValue"
        :ipcanStepTemplates="ipcanStepTemplates"
        :groupedStepDependentInfo="groupedStepDependentInfo"
        :templateValue="templateValue"
        :localData="localData"
        @click="checkClicked(...arguments)"
        :forWhat="forWhat"
      ></stepHeader>
    </v-expansion-panel-header>
    <v-expansion-panel-content
      :id="stepID + '_' + stepType + '_stepContainer'"
      data-is-rendered="false"
      :data-stepid="stepID"
      class="step testLevel application"
      :data-step-identifier="stepIdentifier"
      
    >
      <div>
        <i
          :data-test-step="stepValue"
          data-help-link="stepHelpFlag"
          class="step-help fas fa-question-circle fs16 stepHelp"
        ></i>
      </div>

      <div class="mt-3 requestTable">

              <conditionContainer
          :value="stepStoredValue"
          @input="processStepChanged"
          :result="result"
        >
          <groupCombobox
            :options="groupedTestCases"
            :value="stepTypeSelectorValue"
            @input="updateStepType(...arguments)"
            :result="result"
            :id="'stepSelector_' + stepID"
            :stepType="stepType"
          ></groupCombobox>
        </conditionContainer>
         <div class="mt-5">
          <dynamicComponent
            v-if="typeof templateName != 'undefined' && templateName != null"
            :type="templateName"
            :result="result"
            :additional="{
              stepStoredValue: stepStoredValue,
              stepType: stepType,
              groupedStepDependentInfo: groupedStepDependentInfo,
              additionalStepsInfo: additionalStepsInfo,
              stepID: stepID,
              ipcanStepTemplates: ipcanStepTemplates,
              isValid: isValid,
            }"
            @stored-value-changed="processStepChanged(...arguments)"
            @update-additional-steps-info="updateAdditionalStepsInfo"
            @local-data-changed="updateLocalData"
            @revalidate="$emit('revalidate')"
            @changeIsValid="$emit('changeIsValid', $event)"
          ></dynamicComponent>
        </div>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import conditionContainer from "@/components/legacy/conditionContainer.vue";
import stepHeader from "@/components/legacy/stepHeader.vue";
import dynamicComponent from "@/commonComponents/dynamicComponent.vue";
import groupCombobox from "@/commonComponents/groupCombobox.vue";

import {
  getSubstepMapping,
  checkDiameterProtocol,
  getfilteredTestCaseTypes,
} from "@/js/testHelpers.js";
export default {
  components: {
    conditionContainer,
    stepHeader,
    dynamicComponent,
    groupCombobox,
  },
  data: function () {
    return {
      enableCondition: false,
      localData: null,
    };
  },
  created() {
    this.enableCondition = this.condition.length > 0;
  },
  computed: {
    ipcanStepTemplates: function () {
      let ipcanStepTemplates = {};
      if (
        typeof this.groupedStepDependentInfo != "undefined" &&
        typeof this.groupedStepDependentInfo["ipcanStepTemplates"] !=
          "undefined"
      ) {
        ipcanStepTemplates = this.groupedStepDependentInfo[
          "ipcanStepTemplates"
        ];
      }
      return ipcanStepTemplates;
    },
    localStepValue: {
      get() {
        //console.log(this.step);
        return this.step;
      },
      set(newVal) {
        this.$emit("step-value-changed", newVal);
      },
    },
    condition: {
      get() {
        let condition = "";
        if (typeof this.stepStoredValue.Condition != "undefined") {
          condition = this.stepStoredValue.Condition;
        }
        return condition;
      },
      set(setValue) {
        this.$set(this.stepStoredValue, "Condition", setValue);
        this.processStepChanged(this.stepStoredValue);
      },
    },
    groupedTestCases: function () {
      let options = [];
      for (let [key, value] of Object.entries(this.testCaseTypes)) {
        if (value.length > 0) {
          let headerText = "";
          value.forEach(function (stepInfo) {
            headerText += " " + stepInfo.text;
          });

          let header = this.languagePack("menu_" + key);
          if (key == "2G/3G" || key == "4G" || key == "5G") {
            header += " (shows configured items)";
          }

          options.push({
            text: headerText,
            header: header,
          });
          value.forEach(function (stepInfo) {
            ////console.log(stepInfo);
            //options.push({ value: stepInfo[0], text: stepInfo[1], group: key });
            stepInfo = {
              ...stepInfo,
              selector: stepInfo.value + stepInfo.subtype,
            };
            options.push(stepInfo);
          });
          options.push({ divider: true });
        }
      }
      return options;
    },

    stepType: {
      get() {
        let stepType;
        let stepKeys = Object.keys(this.localStepValue);
        if (stepKeys.indexOf("stepIdentifier") > -1) {
          stepKeys.splice(stepKeys.indexOf("stepIdentifier"), 1);
        }
        if (typeof stepKeys[0] != "undefined") {
          stepType = stepKeys[0];
        }

        return stepType;
      },
    },
    stepTypeSelectorValue: {
      get() {
        ////console.log(this.localStepValue, "getter called");
        // let stepKeys = Object.keys(this.localStepValue);
        // if (stepKeys.indexOf("stepIdentifier") > -1) {
        //   stepKeys.splice(stepKeys.indexOf("stepIdentifier"), 1);
        // }

        let returned = "";
        let stepType = this.stepType;
        //console.log(this.step, stepType);
        if (stepType != "" && typeof this.step[stepType] != "undefined") {
          let subType = this.step[stepType].subType;
          if (typeof subType == "undefined" || subType == "") {
            let mapping = getSubstepMapping();
            if (typeof mapping[stepType] != "undefined") {
              // selectorValue = stepValue + "." + mapping[stepValue];
              subType = mapping[stepType];
            }
          }
          this.groupedTestCases.forEach(function (stepInfo) {
            //console.log(stepInfo.value, stepType, stepInfo.subtype, subType);
            if (subType == "") {
              if (stepInfo.value == stepType) {
                returned = stepInfo;
                return;
              }
            } else if (
              stepInfo.value == stepType &&
              stepInfo.subtype == subType
            ) {
              returned = stepInfo;
              return;
            }
          });
        }
        return returned;
      },
    },
    stepStoredValue: {
      get: function () {
        let stepStoredValue = {};
        ////console.log(this.localStepValue);
        let stepKeys = Object.keys(this.localStepValue);
        ////console.log(stepKeys);
        if (stepKeys.indexOf("stepIdentifier") > -1) {
          stepKeys.splice(stepKeys.indexOf("stepIdentifier"), 1);
        }
        ////console.log(stepKeys);

        let stepType = stepKeys[0];
        if (typeof this.step[stepType] != "undefined") {
          stepStoredValue = this.step[stepType];
        }
        return stepStoredValue;
      },
      set(stepObject) {
        this.updateStep(stepObject);
      },
    },
    templateName: function () {
      let stepType = this.stepType;
      if (typeof stepType != "undefined") {
        if (this.stepType.indexOf("RATTUX_") == 0) {
          return "step_RATTUX";
        } else if (this.stepType.indexOf("Explorer_VF_") == 0) {
          return "step_Explorer_VF";
        } else if (
          checkDiameterProtocol(stepType) ||
          stepType == "IPCAN" ||
          stepType == "DCCA-Session" ||
          stepType == "RADIUS_ACCESS" ||
          stepType == "RADIUS_ACCOUNTING" ||
          stepType == "RADIUS"
        ) {
          return "step_IPCAN";
        }
        return "step_" + this.stepType;
      } else {
        return null;
      }
    },
    additionalStepsInfo: {
      get() {
        return this.result.additional.additionalStepsInfo;
      },
      set(newVal) {
        let localResult = { ...this.result };
        this.$set(localResult.additional, "additionalStepsInfo", newVal);
      },
    },
    testCaseTypes() {
      let storedTestCaseTypes = this.result.additional.testCasesOriginal;
      let testCaseTypes = getfilteredTestCaseTypes(
        this.result.valueLine.subscriberType,
        storedTestCaseTypes,
        this.result.additional.noSubscriberAllowedTypes
      );
      // let testCaseTypesArray = [];
      // for (let cat in testCaseTypes) {
      //   for (let index in testCaseTypes[cat]) {
      //     testCaseTypesArray.push(testCaseTypes[cat][index][0]);
      //   }
      // }
      return testCaseTypes;
    },
  },

  props: [
    "stepID",
    "fieldsObject",
    "stepIdentifier",
    "stepValue",
    "step",
    "stepIncrement",
    "result",
    "groupedStepDependentInfo",
    "templateValue",
    "isValid",
    "forWhat"
  ],
  methods: {
    updateLocalData(localData) {
      this.localData = localData;
    },
    updateStep(stepObject) {
      let localStep = { ...this.step };
      //console.log("setter called", localStep, this.stepType, stepObject);
      this.$set(localStep, this.stepType, stepObject);
      this.$emit("step-value-changed", localStep);
    },
    updateAdditionalStepsInfo(value) {
      this.additionalStepsInfo = value;
    },

    checkClicked(value) {
      console.log(value);
      if (value == "trash") {
        this.$emit("remove-step");
      } else if (value == "insertnext") {
        this.$emit("insert-next-step");
      } else if (value == "copyStep") {
        this.$emit("copy-step");
      }
    },
    processStepChanged(value) {
      //console.log("processStepChanged", value);
      //  this.$set(this, "stepStoredValue", value);
      // let stepObject = {};
      // stepObject[this.stepType] = value;
      //console.log(JSON.stringify(value));
      //  this.stepStoredValue = value;
      // this.$set(stepObject, this.stepType, value);
      // this.$emit("step-value-changed", stepObject);
      this.updateStep(value);
    },
    toggleTestCondition: function (enabled) {
      ////console.log(enabled);
      this.enableCondition = enabled;
    },
    updateStepType(selectedValue) {
      ////console.log(selectedValue);
      // this.stepType=selectedValue.value;
      let stepKeys = Object.keys(this.localStepValue);
      let stepValue = { ...this.localStepValue };
      if (stepKeys.indexOf("stepIdentifier") > -1) {
        stepKeys.splice(stepKeys.indexOf("stepIdentifier"), 1);
      }
      let stepType = stepKeys[0];
      this.$delete(stepValue, stepType);
      // this.stepType = stepType;
      this.$set(stepValue, selectedValue.value, {
        subType: selectedValue.subtype,
        category: selectedValue.category,
      });
      this.localStepValue = stepValue;
      /* console.log(this.localStepValue, "changed");
      this.$nextTick(function () {
        this.$emit("revalidate");
      });*/
    },
  },
};

// if (typeof stepValue == "undefined") {
//   stepValue = "";
// }
// // var step="";
// //fieldsObject.data( "stepsIdentifiers").push(stepIdentifier);
// $testCasesGroup.data("stepsIdentifiers").push(stepIdentifier);

// if (typeof $toBeInsertedAfter != "undefined") {
//   $stepGroup.insertAfter($toBeInsertedAfter);
// } else {
//   $stepGroup.appendTo($testCases);
// }

// //  var inputArray=Object.keys(fieldsObject.data( "testCases"));
// let inputArray = $testCasesGroup.data("inputArray");

// let stepHelpVal = "stepType_" + stepValue;
// let stepHelpFlag = "";
// if (typeof window.stepHelp[stepHelpVal] != "undefined") {
//   stepHelpFlag = window.stepHelp[stepHelpVal];
// }
// var debugButt = "";
// if (stepValue == "Web_Driver") {
//   let debugVal = 0;
//   let debugClass = "";
//   if (debugVal == 1) {
//     debugClass = "activeDebugWDA";
//   }
// }
// let selectorValue = stepValue;
// let subType = "";
// let category = "";
// if (
//   typeof stepStoredValue.subType != "undefined" &&
//   stepStoredValue.subType != ""
// ) {
//   selectorValue = stepValue + "." + stepStoredValue.subType;
//   subType = stepStoredValue.subType;
//   category = stepStoredValue.category;
// } else {
//   let mapping = getSubstepMapping();
//   if (typeof mapping[stepValue] != "undefined") {
//     selectorValue = stepValue + "." + mapping[stepValue];
//     subType = mapping[stepValue];
//   }
// }
// $stepGroup
//   .find(".step")
//   .attr("data-subtype", subType)
//   .attr("data-category", category);

// let $testConditionInput = $stepGroup.find(".testConditionInput");
// var id = $testConditionInput.uniqueId().attr("id");
// $testConditionInput.attr("name", id);
// let $testConditionButton = $stepGroup.find(".testConditionButton");
// if (
//   typeof stepStoredValue.Condition != "undefined" &&
//   stepStoredValue.Condition != ""
// ) {
//   $testConditionInput.val("" + stepStoredValue.Condition);
//   //$stepGroup.find(".testConditionLine").find(".dot").addClass("newGreen");
//   $testConditionButton
//     .addClass("bluePrettyButton")
//     .removeClass("inactivePrettyButton");
//   testTestConditionInput($testConditionInput);
// } else {
//   $testConditionButton
//     .addClass("inactivePrettyButton")
//     .removeClass("bluePrettyButton");
//   $stepGroup
//     .find(".testConditionLine")
//     .hide()
//     .attr("data-hidden", "true");
// }
// if (
//   typeof stepStoredValue.showCondition != "undefined" &&
//   stepStoredValue.showCondition != true
// ) {
//   $stepGroup
//     .find(".testConditionLine")
//     .hide()
//     .attr("data-hidden", "true");
// }

// initializeCombobox($stepGroup);
// var $stepTypeSelector = $stepGroup.find(".stepTypeSelector");
// renderStepHeader($stepTypeSelector, stepStoredValue, this.stepID);

// enableButtons(fieldsObject);
// fieldsObject.find("select.stepTypeSelector").change(function(e) {
//   let source = $(this)
//     .closest(".step")
//     .find(".step-help");
//   //intialize into empty first!
//   source.attr("data-test-step", "");
//   source.attr("data-help-link", "");
//   let stepName = this.value;
//   let stepHelpVal = "stepType_" + stepName;
//   stepHelpFlag = window.stepHelp[stepHelpVal];
//   //   ////console.log(window.stepHelp);
//   source.attr("data-test-step", stepName);
//   source.attr("data-help-link", stepHelpFlag);
//   let buttHolder = $(this)
//     .closest(".step")
//     .find(".debugButtHolder");
//   buttHolder.empty();
//   /* if(stepName=="Web_Driver"){
//         debugButt = "<button href='javascript:void(0);' class='' onClick='updateDebugWDA(this);return false;'>Debug</button>";
//         buttHolder.html(debugButt);
//       }*/

//   var lastCount = $(e)
//     .closest(".testCases")
//     .find(".stepGroup").length;
//   disabledButtons(fieldsObject);
//   if ($(this).val() != "") {
//     enableButtons(fieldsObject);
//   }
// });
// return $stepGroup;
</script>
<style scoped>
</style>