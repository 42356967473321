<template>
    <div v-if="typeof value.content == 'object'" :class="treeClass(value.content)">
      <div v-for="(line, index, i) in value.content" :key="index">
        <v-row no-gutters :class="treeClass(line)">
          <v-col class="ml-3 mt-2" >
            <v-row no-gutters>
              <v-col
                      v-if="treeLength > 1"
                      class="d-inline-block treebg"
                      :style="'max-width:' + treeLength * 15 + 'px'"

              >
                <span>|</span>
                <span v-for="line in treeLength" :key="line">__</span>
              </v-col>
              <v-col>
                <primaryTextfield
                        v-if="value.type != 'array'"
                        :value="index"
                        :fieldAttrInput="{
                  id:
                    'httpBodyJSON_' +
                    treeLength +
                    '_' +
                    getIdIndex(index, i) +
                    '_attribute',
                }"
                        label=""
                        :templateContent="result"
                        @change="updateIndex(index, ...arguments)"
                ></primaryTextfield>
              </v-col>
            </v-row>
          </v-col>

          <v-col style="max-width: 40px; min-width: 40px;">
            <div
                    v-if="line.type == 'array' || line.type == 'object'"
                    class="ml-2 mt-4"

            >
              <v-icon
                      @click="addLine(index)"
                      title="Add"
                      :id="
                'httpBodyJSON_' +
                treeLength +
                '_' +
                getIdIndex(index, i) +
                '_add'
              "
              >mdi-plus</v-icon
              >
            </div> </v-col
          ><v-col class="mt-3">
          <associatedOption
                  :fieldAttributes="{
              name: '',
              associatedOption: types,
            }"
                  type="select"
                  :fieldAttrInput="{
              class: 'required',
              id:
                'httpBodyJSON_' +
                treeLength +
                '_' +
                getIdIndex(index, i) +
                '_type',
            }"
                  v-model="line.type"
                  @input="changeContentType(index, ...arguments)"
                  :templateContent="result"
          ></associatedOption>
        </v-col>
          <v-col class="ml-2 mt-3">
            <jsonInputValue
                    :result="result"
                    :type="line.type"
                    v-model="line.content"
                    :id="
              'httpBodyJSON_' +
              treeLength +
              '_' +
              getIdIndex(index, i) +
              '_content'
            "
            ></jsonInputValue>
          </v-col>
          <v-col class="ml-2 mt-3" style="max-width: 40px">
            <trash
                    @click="removeLine(index)"
                    :id="
              'httpBodyJSON_' +
              treeLength +
              '_' +
              getIdIndex(index, i) +
              '_trash'
            "
            ></trash>
          </v-col>
        </v-row>

        <jsonContentRecursive
                :value="line"
                :result="result"
                :treeLength="treeLength + 1"
        ></jsonContentRecursive>
      </div>
    </div>
</template>
<script>
// import lineFields from "@/commonComponents/lineFields.vue";
import jsonInputValue from "@/components/legacy/jsonInputValue.vue";
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import associatedOption from "@/commonComponents/associatedOption.vue";
import trash from "@/commonComponents/dynamic/trash.vue";
// import lineButtons from "@/cfg/lineButtons.json";
export default {
  name: "jsonContentRecursive",
  components: { trash, primaryTextfield, associatedOption, jsonInputValue },

  props: ["value", "result", "treeLength"],
  data() {
    return {
      types: [
        ["array", "Array"],
        ["string", "String"],
        ["boolean", "Boolean"],
        ["null", "Null"],
        ["number", "Number"],
        ["object", "Object"],
      ],
      // cachedPairArray: null,
    };
  },
  methods: {

    getIdIndex(index, i) {
      let idIndex = index;
      if (typeof i != "undefined") {
        idIndex = i;
      }
      return idIndex;
    },
    removeLine(index) {
      this.$delete(this.value.content, index);
      this.$emit("input", this.value);
    },
    changeContentType(index, type) {
      console.log("type");
      let newVal = "";
      if (type == "array") {
        newVal = [];
      } else if (type == "object") {
        newVal = {};
      } else if (type == "boolean") {
        newVal = true;
      } else if (type == "null") {
        newVal = null;
      } else if (type == "number") {
        newVal = Number("");
      }
      this.$set(this.value.content[index], "content", newVal);
      let value = this.value;
      if (index == "") {
        // when value is empty it dosent updated correctly so force to updates
        value = JSON.parse(JSON.stringify(this.value));
      }
      this.$emit("input", value);
    },
    updateIndex(index, newIndex) {
      let localValue = { ...this.value };
      let indexValue = localValue.content[index];
      this.$delete(localValue.content, index);
      this.$set(localValue.content, newIndex, indexValue);
      this.$emit("input", localValue);
    },
    addLine(index) {
      let line = { type: "string", content: "" };
      let storedBody = this.value.content[index];
      if (storedBody.type == "array") {
        storedBody.content.push(line);
      } else {
        this.$set(storedBody.content, "", line);
      }
      // this.$emit("input", storedBody);
    },
    treeClassCompute(line){
          let className="";
          if(this.treeLength>1 || line.type=="object" || line.type=="array"){
            className="treeBg";
          }
          return className;
      },
  },
  computed: {
      treeClass: function() {
          return (line) => this.treeClassCompute(line);
      }
  },
};
</script>