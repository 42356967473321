<template>
    <div>
        <configContainer title="Condition">
            <template v-slot:content>
                <div>
                    <associatedOption
                            type="select"
                            :fieldAttributes="{ options: requestTypLADP, name: 'Request Type' }"
                            :fieldAttrInput="{ class: 'required',id:'requestType'}"
                            v-model="requestType"
                            :templateContent="result"
                            :isValid="isValid"
                    ></associatedOption>
                </div>
                <div>
                    <primaryTextfield
                            type="text"
                            :fieldAttrInput="{ class: 'required',name:'Object',id:'object' }"
                            v-model="object"
                            :templateContent="result"
                            :isValid="isValid"
                            label="Object"
                    ></primaryTextfield>
                </div>
                <div>
                    <associatedOption
                            type="select"
                            :fieldAttributes="{ options: options.operationLADP, name: 'Operation' }"
                            :fieldAttrInput="{ class: 'required',id:'operation' }"
                            v-model="operation"
                            :templateContent="result"
                            :isValid="isValid"
                    ></associatedOption>
                </div>
                <evalContentTable
                        v-model="localEval"
                        type="expect"
                        operatorType="incNull"
                        :result="result"
                        :isValid="isValid"
                ></evalContentTable>
            </template>
        </configContainer>
        <subStepsModule
                :groupedStepDependentInfo="groupedStepDependentInfo"
                :stepStoredValue="localStoredValue"
                :stepID="stepID"
                :result="result"
                @group-value-changed="updateTestValue(...arguments)"
                :isValid="isValid"
        ></subStepsModule>
        <configContainer title="Response">
            <template v-slot:content>
                <div>
                    <associatedOption
                            type="combobox"
                            :fieldAttributes="{ options: options.responseLDAP, name: 'Response Type' }"
                            :fieldAttrInput="{ id:'responseType' }"
                            v-model="responseType"
                            :templateContent="result"
                            :isValid="isValid"
                    ></associatedOption>
                </div>
                <div>
                    <associatedOption
                            type="combobox"
                            :fieldAttributes="{ options: options.resultCodeLDAP, name: 'Result Code'}"
                            :fieldAttrInput="{ id:'resultCode' }"
                            v-model="resultCode"
                            :templateContent="result"
                            :isValid="isValid"
                    ></associatedOption>
                </div>
            </template>
        </configContainer>
    </div>
</template>
<script>
  import subStepsModule from "@/components/legacy/subStepsModule.vue";
  import evalContentTable from "@/components/legacy/evalContentTable.vue";
  import configContainer from "@/commonComponents/configContainer.vue";
  import associatedOption from "@/commonComponents/associatedOption.vue";
  import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
  import options from "@/cfg/options.json";

  export default {
    components: {
      subStepsModule,
      evalContentTable,
      configContainer,
      associatedOption,
      primaryTextfield,
    },
    data() {
      return {
        requestTypLADP: [
          ["6", "ModifyRequest"],
        ],
        options: options
      };
    },
    props: [
      "groupedStepDependentInfo",
      "stepStoredValue",
      "result",
      "stepID",
      "stepType",
      "ipcanStepTemplates",
      "isValid"
    ],
    computed: {
      requestType: {
        get() {
          let requestTypeVal = {};
          if (
            typeof this.localStoredValue.check != "undefined" &&
            typeof this.localStoredValue.check.requestType != "undefined"
          ) {
            requestTypeVal = this.localStoredValue.check.requestType;
          }
          return requestTypeVal;
        },
        set(newVal) {
          this.$set(this.localStoredValue.check, "requestType", newVal);
          this.$emit("stored-value-changed", this.localStoredValue);
        },
      },
      object: {
        get() {
          let objectVal = "";
          if (
            typeof this.localStoredValue.check != "undefined" &&
            typeof this.localStoredValue.check.object != "undefined"
          ) {
            objectVal = this.localStoredValue.check.object;
          }
          return objectVal;
        },
        set(newVal) {
          this.$set(this.localStoredValue.check, "object", newVal);
          this.$emit("stored-value-changed", this.localStoredValue);
        },
      },
      operation: {
        get() {
          let operationVal = {};
          if (
            typeof this.localStoredValue.check != "undefined" &&
            typeof this.localStoredValue.check.operation != "undefined"
          ) {
            operationVal = this.localStoredValue.check.operation;
          }
          return operationVal;
        },
        set(newVal) {
          this.$set(this.localStoredValue.check, "operation", newVal);
          this.$emit("stored-value-changed", this.localStoredValue);
        },
      },
      resultCode: {
        get() {
          let resultCodeVal = {};
          if (
            typeof this.localStoredValue.response != "undefined" &&
            typeof this.localStoredValue.response.parameters != "undefined" &&
            typeof this.localStoredValue.response.parameters.resultCode != "undefined"
          ) {
            resultCodeVal = this.localStoredValue.response.parameters.resultCode;
          }
          return resultCodeVal;
        },
        set(newVal) {
          this.$set(this.localStoredValue.response.parameters, "resultCode", newVal);
          this.$emit("stored-value-changed", this.localStoredValue);
        },
      },
      responseType: {
        get() {
          let responseTypeVal = {};
          if (
            typeof this.localStoredValue.response != "undefined" &&
            typeof this.localStoredValue.response.parameters != "undefined" &&
            typeof this.localStoredValue.response.parameters.responseType != "undefined"
          ) {
            responseTypeVal = this.localStoredValue.response.parameters.responseType;
          }
          return responseTypeVal;
        },
        set(newVal) {
          this.$set(this.localStoredValue.response.parameters, "responseType", newVal);
          this.$emit("stored-value-changed", this.localStoredValue);
        },
      },
      parameters() {
        let parameters = {};
        if (
          typeof this.stepStoredValue.response != "undefined" &&
          typeof this.stepStoredValue.response.parameters != "undefined"
        ) {
          parameters = this.stepStoredValue.response.parameters;
        }
        return parameters;
      },
      localStoredValue: {
        get() {
          return this.stepStoredValue;
        },
        set(newValue) {
          console.log("new value");
          this.$emit("stored-value-changed", newValue);
        },
      },
      localEval: {
        get() {
          let evalValue = {};
          if (
            typeof this.localStoredValue.check != "undefined" &&
            typeof this.localStoredValue.check.eval != "undefined"
          ) {
            evalValue = this.localStoredValue.check.eval;
          }
          return evalValue;
        },
        set(newVal) {
          this.$set(this.localStoredValue.check, "eval", newVal);
          this.$emit("stored-value-changed", this.localStoredValue);
        },
      },
    },
    methods: {
      updateTestValue(substepsValue) {
        let local = JSON.parse(JSON.stringify(substepsValue));
        console.log(local);
        this.$set(this.localStoredValue, "substeps", local);
        this.$emit("stored-value-changed", this.localStoredValue);
      },
    },
  };
</script>