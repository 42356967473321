<template>
  <div>
    <tabsStructure
            :tabNames="tabNames"
            tabsPrefix="http"
            :isValid="isValid"
            v-on="$listeners">

      <template v-slot:tabsContent="data">
        <div v-if="data.item.index === 'Body'">

          <httpExpectBody
                  :localStoredStep="stepStoredValue"
                  v-on="$listeners"
                  :result="result"
                  :isValid="isValid"
                  :hideDetails="false">
          </httpExpectBody>
        </div>
        <slot name="tabsContent" :item="data.item.index"></slot>
      </template>
    </tabsStructure>
  </div>

</template>
<script>
import tabsStructure from "@/components/legacy/tabsStructure.vue";
import httpExpectBody from "@/components/legacy/httpExpectBody.vue";

export default {
  components: {tabsStructure, httpExpectBody},
  computed: {
    tabNames() {
      let tabNames = [
        {index: "Header", name: "Header", disabled: false},
        {index: "Parameter", name: "Parameter", disabled: false},
        {index: "Body", name: "Body", disabled: false},
      ];
      if (this.packetType === "HTTP_RESPONSE" || this.requestType === "GET") {
        tabNames = [
          {index: "Header", name: "Header", disabled: false},
          {index: "Body", name: "Body", disabled: false},
        ];
      }
      if (this.requestType === "GET") {
        tabNames = [
          {index: "Header", name: "Header", disabled: false},
          {index: "Parameter", name: "Parameter", disabled: false},
        ];
      }
      return tabNames;
    },
  },
  props: ["packetType", "requestType", "isValid", "result", "stepStoredValue"],
};

// function showTab(that) {
//   let method = $(that).val();
//   let $mainAttributesContainer = $(that).closest(".mainAttributesContainer");
//   let source = $mainAttributesContainer.find("li.Body");
//   let $BodyContainer = $mainAttributesContainer.find(".BodyContainer");

//   if (method == "GET") {
//     let $tabContainer = $mainAttributesContainer.find(".tabContainer");
//     var active = $tabContainer.tabs("option", "active");
//     source.hide();
//     $BodyContainer.hide();
//     if (active == 2) {
//       $tabContainer.tabs("option", "active", 1);
//     }
//   } else {
//     //$BodyContainer.show();
//     source.show();
//   }
// }
</script>