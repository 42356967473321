<template>
  <div class="subSteps">
    <configContainer title="Substep">
      <template v-slot:content>
        <testCasesGroup
          :templateValue="substeps"
          :groupedStepDependentInfo="substepDependentInfo"
          :result="result"
          v-on="$listeners"
          :isValid="isValid"
          forWhat="substep"
        ></testCasesGroup>
      </template>
    </configContainer>
  </div>
</template>
<script>
import configContainer from "@/commonComponents/configContainer.vue";
import testCasesGroup from "@/components/legacy/testCasesGroup.vue";
export default {
  components: { testCasesGroup, configContainer },
  props: ["groupedStepDependentInfo", "stepStoredValue", "stepID", "result","isValid"],
  computed: {
    substeps() {
      let substeps = {};
      let stepStoredValue = this.stepStoredValue;
      if (
        typeof stepStoredValue.substeps == "object" &&
        !Array.isArray(stepStoredValue.substeps)
        //&&
        // stepStoredValue.parameters.packetType == this.packetType
      ) {
        //stored and selected packet type  should be same otherwise substep would be null
        substeps = stepStoredValue.substeps;
      }
      return substeps;
    },
    substepDependentInfo() {
      let expectInfo = { ipcanStepTemplates: {}, indexedTemplates: {} };
      if (
        typeof this.groupedStepDependentInfo["expectSubStep"] != "undefined" &&
        typeof this.groupedStepDependentInfo["expectSubStep"][this.stepID] !=
          "undefined"
      ) {
        try {
          if(typeof this.groupedStepDependentInfo["expectSubStep"][this.stepID]["Test"]!="undefined"){
            expectInfo = JSON.parse(
                                JSON.stringify(
                                            this.groupedStepDependentInfo["expectSubStep"][this.stepID]["Test"]
                                )
                          );
          }
        } catch (error) {
          console.log(error);
        }
      }
      if (typeof expectInfo["ipcanStepTemplates"] == "undefined") {
        expectInfo["ipcanStepTemplates"] = {};
      }
      return expectInfo;
    },
  },
  methods: {},
};

// //for Expect at the moment!
// function subStepsModule(
//   $requestTable,
//   stepStoredValue,
//   stepID,
//   fieldsObject,
//   packetType
// ) {
//   $testCasesGroup.data("templateValue", substeps);
//   $testCasesGroup.data("ipcanStepTemplates", expectInfo["ipcanStepTemplates"]);
//   $testCasesGroup.data("indexedTemplates", expectInfo["indexedTemplates"]);
//   $testCasesGroup.data("objectsArray", {});
//   $testCasesGroup.data("template", {});
//   let inputArray = fieldsObject.data("testCases");
//   let resultInputArray = updateExpectAllowedType(inputArray);
//   $testCasesGroup.data("inputArray", resultInputArray);
//   storeTestCaseValues(
//     fieldsObject,
//     $testCasesGroup,
//     $testCasesGroup.data("templateValue")
//   );
// }
</script>