<template>
  <v-card class="mb-6 mt-3" elevation="0" outlined>
    <containerTitle :title="title">
      <slot name="header"></slot>
    </containerTitle>
    <v-container>
      <slot name="content"></slot>
    </v-container>
  </v-card>
</template>
<script>
import containerTitle from "@/commonComponents/containerTitle.vue";
export default {
  name: "configContainer",
  components: { containerTitle },
  props: ["title"],
};
</script>