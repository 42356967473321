<template>
  <div :style="maxWidth(this.forWhat)">
    <lineFieldsArray
      :fieldAttrRow="{}"
      :fields="fields"
      :templateContent="{ decodeType: this.forWhat, ...result }"
      :value="pairArray"
      @line-field-input="checkInput(...arguments)"
      :headerTitle="true"
      :addable="true"
      addButtTitle="Add Attribute"
      :isValid="isValid"
      :linePrefix="linePrefix"
      :hideDetails="hideDetails"
    ></lineFieldsArray>
  </div>
</template>
<script>
import options from "@/cfg/options.json";
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import { getOperators } from "@/js/testStepsHelpers/commonTestFunctions.js";
import lineButtons from "@/cfg/lineButtons.json";
export default {
  components: { lineFieldsArray },
  data: function () {
    return {
      pairLength: 0,
    };
  },
  props: {
    // value: {},
    //addable: { type: Boolean, default: true },
    //headerTitle: { type: Boolean },
    operatorType: {},
    forWhat: String,
    type: { type: String, default: "" },
    correctOperators: { type: Boolean, default: true },
    value: {
      default() {
        return {};
      },
    },
    result: {},
    isValid: { type: Boolean },
    linePrefix: { type: String, default: "" },
    hideDetails: { type: Boolean, default: false },
  },
  computed: {
    fields() {
      let fields = {
        attribute: {
          type: "text",
          name: "Attribute",
          inputFieldFormatter: "evalContentAttributeFormatter",
          fieldAttrInput: { class: "required" },
        },
        operator: {
          type: "select",
          associatedOption: this.evalResultOperator,
          name: "Operator",
          fieldAttrInput: { class: "required" },
        },
        content: {
          inputFieldFormatter: "evalContentFormatter",
          type: "text",

          name: "Value",
          fieldAttrInput: { class: "required" },
        },
        else: {
          input: false,
          type: "string",
          string: "else",
          colAttrs: {
            style: "max-width: 27px;",
          },
        },
        result: {
          type: "select",
          associatedOption: options.ldapResult,
          name: "Result",
          fieldAttrInput: { class: "required" },
          default: "error",
        },
        action: {
          type: "select",
          associatedOption: options.evalAction,
          name: "Action",
          default: "stopTest",
          fieldAttrInput: { class: "required" },
        },
          info: {
            type: "text",
            name: "Info",
            fieldAttrInput: { class: "" ,maxlength:'100' },
          },
        remove: lineButtons.remove,
      };
      if (this.type == "expect") {
        this.$delete(fields, "else");
        this.$delete(fields, "result");
        this.$delete(fields, "action");
        this.$delete(fields, "info");
      }
      return fields;
    },
    pairArray: {
      get() {
        let pairArray = [];
        if (typeof this.value == "object" && !Array.isArray(this.value)) {
          var cIndexValue = this.pairLength;
          //var cIndexValue=0;
          for (const [key, value] of Object.entries(this.value)) {
            //cIndexValue += 1;
            value.forEach((element) => {
              pairArray.push({
                cIndex: cIndexValue,
                attribute: key,
                ...element,
              });
            });
          }
        }
        //cIndex helps us to keep new order,keep sort according,so it will never jump like before!
        //console.log("new value",pairArray);
        pairArray.sort(function (a, b) {
          return a.cIndex - b.cIndex;
        });
        console.log("new value", pairArray);
        return pairArray;
      },
      // set(newValue) {
      //     console.log("new value",newValue);
      // }
    },
    evalResultOperator() {
      let operators = options.operators;
      if (this.correctOperators) {
        operators = options.correctOperators;
      }
      if (typeof this.forWhat != "undefined" && this.forWhat == "text") {
        operators = ["contains", "does not contain"];
      }
      return getOperators(this.operatorType, operators); //similar to
    },
  },
  methods: {
    maxWidth(value) {
      let style = "";
      this.pairLength = this.pairArray.length;
      if (
        typeof value != "undefined" &&
        value == "text" &&
        this.pairArray.length > 0
      ) {
        //apply only when 1+ row appears!
        style = "max-width:fit-content";
      }
      return style;
    },
    checkInput(newValueArray) {
      // console.log(value);
      // this.$emit("input", value);

      let request = {};
      newValueArray.forEach((line) => {
        let attribute = line.attribute;
        let insertedLine = { ...line };
        this.$delete(insertedLine, "attribute");
        if (typeof request[attribute] != "undefined") {
          request[attribute].push(insertedLine);
        } else {
          request[attribute] = [insertedLine];
        }
      });
      console.log(request);
      this.$emit("input", request);
    },
  },
};

// export default {
//   props: { forWhat: String, evalResultRowSet: Object },
//   computed: {
//     checkPath() {
//       let checkPath = "";
//       let forWhat = this.forWhat;
//       if (typeof forWhat != "undefined" && forWhat == "JSON") {
//         checkPath = "jsonPathCheck";
//       } else if (
//         typeof forWhat != "undefined" &&
//         (forWhat == "text/xml" || forWhat == "XML")
//       ) {
//         checkPath = "xPathCheck";
//       }
//       return checkPath;
//     }
//   }
// };
// function checkandhide(checkArray, hideTag, that) {
//   let $caller = $(that);
//   let value = $caller.find("select").val();
//   if (typeof value == "undefined") {
//     value = $caller.text();
//   }
//   let $hideTag = $caller.closest("tr").find(hideTag);
//   if ($.inArray(value, checkArray) != -1) {
//     $hideTag.hide();
//   } else {
//     $hideTag.show();
//   }
//   if (value == "regex") {
//     $hideTag.addClass("regexVariable");
//   } else {
//     $hideTag.removeClass("regexVariable");
//   }
// }
// function getEvalContentTable($evalAttrContainer, evalResultRowSet, titleArray, operatorType, addButtonName, whatFor, special) {
//   if (typeof addButtonName == "undefined" || addButtonName == "") {
//     addButtonName = "Add Attribute";
//   }
//   if (special == "expect") {
//     addButtonName = "Add Check";
//   }
//   let evalResultHeader = "";
//   if (special != "expect") {
//     evalResultHeader = "";
//   }

//   let evalButton = ;

//   $evalAttrContainer.append(evalContent);

//   let removeButton = ;
//   let $fieldsObject = $evalAttrContainer.closest(".fields");

//   if (typeof evalResultRowSet != "undefined" && Object.keys(evalResultRowSet).length > 0) {
//     let operators = $fieldsObject.data("operators");
//     let evalAction = $fieldsObject.data("evalAction");
//     let $header = $evalAttrContainer.find('.evalResultHeadLine');
//     let $evalLinesContainer = $evalAttrContainer.find(".evalLinesContainer");

//     $.each(evalResultRowSet, function (attribute, valueArray) {
//       $.each(valueArray, function (index, value) {
//         let $row = constructEvaluationView(attribute, value, removeButton, operators, evalAction, operatorType, whatFor, special);
//         $evalLinesContainer.append($row);
//         checkandhide(["exists", "does not exist", 'is null', 'is not null'], '.evalResultValue', $row.find(".evalResultOperatorContainer"));
//       });
//     });
//     $header.show();
//   } else {  //Fresh
//     //let evalRow += constructEvalView('','',removeButton);
//   }
// }

// function constructEvaluationView(attribute, value,  operators, evalAction, operatorType, forWhat, special) {
//   let checkPath = "";
//   if (typeof forWhat != "undefined" && forWhat == "JSON") {
//     checkPath = "jsonPathCheck";
//   }
//   else if (typeof forWhat != "undefined" && (forWhat == "text/xml" || forWhat == "XML")) {
//     checkPath = "xPathCheck";
//   }
//   let attrIn = "<input type='text'   class='evalResultAttribute normalinput required " + checkPath + "' name='attribute' value='" + attribute + "' >";
//   if (typeof forWhat != "undefined" && forWhat == "text") {
//     attrIn = "<div class='w100'>Content</div>";
//     operators = ["contains", "does not contain"];
//   }
//   let evalResultOperator = getOperators(operatorType, operators);//similar to
//   let $evalResultRow;
//   if (typeof special != "undefined" && special == "expect") {
//     $evalResultRow = $("<tr style='' class='evalResultRow singleCommandAttributes' data-attrRealName='evalResult'><td>" + attrIn + "</td><td class='evalResultOperatorContainer '>" + makeSelector(evalResultOperator, value.operator, "evalResultOperator shortinput required") + "</td><td class='evalEvalValueContainer'><input type='text' placeholder=' Value ' class='evalResultValue normalinput required' name='value' value='" + value.content + "' ></td>  <td class='evalResultRemove ' style='padding-right:7px;'>" + "<a href='javascript:void(0);' onclick='removeEvalRow(this)' title='Delete Attribute'><i class='fas fa-trash fs16 iconRed' ></i></a>" + "</td><td class='fieldPlaceHolder'></td></tr>");
//     let $evalResultAttribute = $evalResultRow.find(".evalResultAttribute");
//     let evalResultAttributeId = $evalResultAttribute.uniqueId().attr('id');
//     $evalResultAttribute.attr("name", evalResultAttributeId);
//     let $evalResultOperator = $evalResultRow.find(".evalResultOperator");
//     let evalResultOperatorId = $evalResultOperator.uniqueId().attr('id');
//     $evalResultOperator.attr("name", evalResultOperatorId);
//     let $evalResultValue = $evalResultRow.find(".evalResultValue");
//     let evalResultValueId = $evalResultValue.uniqueId().attr('id');
//     $evalResultValue.attr("name", evalResultValueId);

//   } else {
//     $evalResultRow = $("<tr style='' class='evalResultRow singleCommandAttributes' data-attrRealName='evalResult'><td>" + attrIn + "</td><td class='evalResultOperatorContainer '>" + makeSelector(evalResultOperator, value.operator, "evalResultOperator shortinput required") + "</td><td class='evalEvalValueContainer'><input type='text' placeholder=' Value ' class='evalResultValue normalinput required' name='value' value='" + value.content + "' ></td><td><div>else</div></td><td class=''>" + makeSelector(window.ldapSearchResult, value.result, "evalResult shortinput required") + "</td><td class=''>" + makeSelector(evalAction, value.action, "evalAction shortinput required") + "</td><td class='evalResultRemove ' style='padding-right:7px;'>" + removeButton + "</td><td class='fieldPlaceHolder'></td></tr>");
//     let $evalResultAttribute = $evalResultRow.find(".evalResultAttribute");
//     let evalResultAttributeId = $evalResultAttribute.uniqueId().attr('id');
//     $evalResultAttribute.attr("name", evalResultAttributeId);
//     let $evalResultOperator = $evalResultRow.find(".evalResultOperator");
//     let evalResultOperatorId = $evalResultOperator.uniqueId().attr('id');
//     $evalResultOperator.attr("name", evalResultOperatorId);
//     let $evalResultValue = $evalResultRow.find(".evalResultValue");
//     let evalResultValueId = $evalResultValue.uniqueId().attr('id');
//     $evalResultValue.attr("name", evalResultValueId);
//     let $evalResult = $evalResultRow.find(".evalResult");
//     let evalResultId = $evalResult.uniqueId().attr('id');
//     $evalResult.attr("name", evalResultId);
//     let $evalAction = $evalResultRow.find(".evalAction");
//     let evalActionId = $evalAction.uniqueId().attr('id');
//     $evalAction.attr("name", evalActionId);
//   }

//   return $evalResultRow;
// }

// function constructEvaluationView(attribute, value, removeButton, operators, evalAction, operatorType, forWhat, special) {
//   let checkPath = "";
//   if (typeof forWhat != "undefined" && forWhat == "JSON") {
//     checkPath = "jsonPathCheck";
//   }
//   else if (typeof forWhat != "undefined" && (forWhat == "text/xml" || forWhat == "XML")) {
//     checkPath = "xPathCheck";
//   }
//   let attrIn = "<input type='text'   class='evalResultAttribute normalinput required " + checkPath + "' name='attribute' value='" + attribute + "' >";
//   if (typeof forWhat != "undefined" && forWhat == "text") {
//     attrIn = "<div class='w100'>Content</div>";
//     operators = ["contains", "does not contain"];
//   }
//   let evalResultOperator = getOperators(operatorType, operators);//similar to
//   let $evalResultRow;
//   if (typeof special != "undefined" && special == "expect") {
//     $evalResultRow = $("<tr style='' class='evalResultRow singleCommandAttributes' data-attrRealName='evalResult'><td>" + attrIn + "</td><td class='evalResultOperatorContainer '>" + makeSelector(evalResultOperator, value.operator, "evalResultOperator shortinput required") + "</td><td class='evalEvalValueContainer'><input type='text' placeholder=' Value ' class='evalResultValue normalinput required' name='value' value='" + value.content + "' ></td>  <td class='evalResultRemove ' style='padding-right:7px;'>" + removeButton + "</td><td class='fieldPlaceHolder'></td></tr>");
//     let $evalResultAttribute = $evalResultRow.find(".evalResultAttribute");
//     let evalResultAttributeId = $evalResultAttribute.uniqueId().attr('id');
//     $evalResultAttribute.attr("name", evalResultAttributeId);
//     let $evalResultOperator = $evalResultRow.find(".evalResultOperator");
//     let evalResultOperatorId = $evalResultOperator.uniqueId().attr('id');
//     $evalResultOperator.attr("name", evalResultOperatorId);
//     let $evalResultValue = $evalResultRow.find(".evalResultValue");
//     let evalResultValueId = $evalResultValue.uniqueId().attr('id');
//     $evalResultValue.attr("name", evalResultValueId);

//   } else {
//     $evalResultRow = $("<tr style='' class='evalResultRow singleCommandAttributes' data-attrRealName='evalResult'><td>" + attrIn + "</td><td class='evalResultOperatorContainer '>" + makeSelector(evalResultOperator, value.operator, "evalResultOperator shortinput required") + "</td><td class='evalEvalValueContainer'><input type='text' placeholder=' Value ' class='evalResultValue normalinput required' name='value' value='" + value.content + "' ></td><td><div>else</div></td><td class=''>" + makeSelector(window.ldapSearchResult, value.result, "evalResult shortinput required") + "</td><td class=''>" + makeSelector(evalAction, value.action, "evalAction shortinput required") + "</td><td class='evalResultRemove ' style='padding-right:7px;'>" + removeButton + "</td><td class='fieldPlaceHolder'></td></tr>");
//     let $evalResultAttribute = $evalResultRow.find(".evalResultAttribute");
//     let evalResultAttributeId = $evalResultAttribute.uniqueId().attr('id');
//     $evalResultAttribute.attr("name", evalResultAttributeId);
//     let $evalResultOperator = $evalResultRow.find(".evalResultOperator");
//     let evalResultOperatorId = $evalResultOperator.uniqueId().attr('id');
//     $evalResultOperator.attr("name", evalResultOperatorId);
//     let $evalResultValue = $evalResultRow.find(".evalResultValue");
//     let evalResultValueId = $evalResultValue.uniqueId().attr('id');
//     $evalResultValue.attr("name", evalResultValueId);
//     let $evalResult = $evalResultRow.find(".evalResult");
//     let evalResultId = $evalResult.uniqueId().attr('id');
//     $evalResult.attr("name", evalResultId);
//     let $evalAction = $evalResultRow.find(".evalAction");
//     let evalActionId = $evalAction.uniqueId().attr('id');
//     $evalAction.attr("name", evalActionId);
//   }

//   return $evalResultRow;
// }
// function getEvalContentTable($evalAttrContainer, evalResultRowSet, titleArray, operatorType, addButtonName, whatFor, special) {
//   if (typeof addButtonName == "undefined" || addButtonName == "") {
//     addButtonName = "Add Attribute";
//   }
//   if (special == "expect") {
//     addButtonName = "Add Check";
//   }
//   let evalResultHeader = "";
//   if (special != "expect") {
//     evalResultHeader = "<thead><tr class='evalResultHeadLine'  style='display: none;'><th style=''>" + titleArray["attribute"] + "</th><th style=''>" + titleArray["operator"] + "</th><th class='evalValueContainer'>" + titleArray["value"] + "</th><th></th><th style=''>Result</th><th style=''>Action</th><th></th><th class='fieldPlaceHolder'></th></tr></thead>";
//   }

//   let evalButton = '<button href="javascript:void(0);" onclick="addEvaluationRow(this);return false" class="addResponse "><i class="fas fa-plus-square fs16 textbuttonicon"></i> <span class="buttLabelWithIcon">' + addButtonName + '</span></button>';
//   let evalContent = '<div class="evalContentWrapper"><table class="evalListTable">' + evalResultHeader + '<tbody class="evalLinesContainer" data-operatorType="' + operatorType + '"></tbody></table>' + evalButton + '</div>';
//   $evalAttrContainer.append(evalContent);

//   let removeButton = "<a href='javascript:void(0);' onclick='removeEvalRow(this)' title='Delete Attribute'><i class='fas fa-trash fs16 iconRed' ></i></a>";
//   let $fieldsObject = $evalAttrContainer.closest(".fields");

//   if (typeof evalResultRowSet != "undefined" && Object.keys(evalResultRowSet).length > 0) {
//     let operators = $fieldsObject.data("operators");
//     let evalAction = $fieldsObject.data("evalAction");
//     let $header = $evalAttrContainer.find('.evalResultHeadLine');
//     let $evalLinesContainer = $evalAttrContainer.find(".evalLinesContainer");

//     $.each(evalResultRowSet, function (attribute, valueArray) {
//       $.each(valueArray, function (index, value) {
//         let $row = constructEvaluationView(attribute, value, removeButton, operators, evalAction, operatorType, whatFor, special);
//         $evalLinesContainer.append($row);
//         checkandhide(["exists", "does not exist", 'is null', 'is not null'], '.evalResultValue', $row.find(".evalResultOperatorContainer"));
//       });
//     });
//     $header.show();
//   } else {  //Fresh
//     //let evalRow += constructEvalView('','',removeButton);
//   }
// }
</script>