<template>
  <div>
     <fieldsRows
      :templateContent="localTemplateContent"
      @field-value-changed="updateFieldValue(...arguments)"
      @field-value-deleted="deleteFieldValue(...arguments)"
      @updated-fields="emitUpdateFields"
      :valueLine="value"
      :isValid="isValid"
      @revalidate="$emit('revalidate')"
    ></fieldsRows>
  </div>
</template>
<script>
import fieldsRows from "@/commonComponents/fieldsRows.vue";
import { getRandomInt } from "@/js/helper.js";
export default {
  components: { fieldsRows },
  props: [
    "templateContent",
    "languageIndex",
    "value",
    "updatedContent",
    "isValid",
  ],
  data() {
    return {
      dialogRef: "",
      dialogDataObject: this.$store.state.dialogDataObject,
      // valueLine: {},
    };
  },

  created: function () {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "dialogResultChanged") {
        //console.log("dialogResultChanged");
        this.dialogDataObject = state.dialogDataObject;
      }
    });

    this.dialogRef = "dialog" + getRandomInt(0, 1000);
    this.$emit("dialog-ref-changed", this.dialogRef);
  },
  watch: {
    templateContent(val) {
      //console.log("changed", val);
      this.$set(val, "dialogRef", this.dialogRef);
      //  let builtResult = JSON.parse(JSON.stringify(templateContent));
      this.$store.commit("dialogResultChanged", val);
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  },

  computed: {
    storedTemplateContent() {
      //console.log(this.value);
      let result;
      if (
        typeof this.dialogDataObject != "undefined" &&
        typeof this.dialogDataObject[this.dialogRef] != "undefined"
      ) {
        result = this.dialogDataObject[this.dialogRef].result;
      } else {
        ////console.log(JSON.parse(JSON.stringify(templateContent)));
        let templateContent = { ...this.templateContent };
        this.$set(templateContent, "dialogRef", this.dialogRef);
        let builtResult = JSON.parse(JSON.stringify(templateContent));
        this.$store.commit("dialogResultChanged", builtResult);
        result = this.dialogDataObject[this.dialogRef].result;
      }
      return result;
    },
    localTemplateContent() {
      let templateContent = {
        ...this.storedTemplateContent,
        ...this.updatedContent,
      };
      //  //console.log(this.value);
      // delete templateContent.fields.command;
      this.$set(
        templateContent,
        "fields",
        this.buildFieldsValues(templateContent.fields, this.value)
      );

      // if (JSON.stringify(this.valueLine) !== JSON.stringify(this.value)) {
      //   this.$emit("input", this.valueLine);
      // }
      this.$set(
        templateContent,
        "fields",
        this.languageHelpProvider(templateContent.fields, this.languageIndex)
      );

      this.$set(templateContent, "valueLine", this.value);

      //console.log(templateContent);

      ////console.log(JSON.parse(JSON.stringify(templateContent)));
      return JSON.parse(JSON.stringify(templateContent));
    },
  },
  methods: {
    emitUpdateFields(info) {
      this.$emit("updated-fields", info);
    },
    updateFieldValue(field, value) {
      ////console.log("changed", field, value);
      let localStoredStep = { ...this.value };
      localStoredStep[field] = value;
      this.$emit("input", localStoredStep);
    },

    deleteFieldValue(field) {
      ////console.log("changed", field, value);
      let localStoredStep = { ...this.value };
      this.$delete(localStoredStep, field);
      this.$emit("input", localStoredStep);
    },

    /**
     * [if you have field config and a object of values you can use buildFieldsValues function]
     *
     * @param   {object}  fields     [object of fields you want to add values to]
     * @param   {Object}  valueLine  [valueLine description]
     *
     * @return  {[type]}             [return the fields after built]
     */
    buildFieldsValues(fields, valueLine) {
      for (let [key, fieldInfo] of Object.entries(fields)) {
        /* if(typeof fieldInfo.value!="undefined"){
          fieldInfo = {
            value: name,
          };
        }*/
        if (typeof fieldInfo == "string") {
          // //console.log(value, " is a string ");
          let name = fieldInfo;
          fieldInfo = {
            value: name,
          };
        }
        if (Array.isArray(fieldInfo)) {
          ////console.log(value, " is an array ");
          let options = fieldInfo;
          fieldInfo = {
            type: "select",
            options: options,
          };
        } else if (typeof fieldInfo == "object") {
          if (
            typeof fieldInfo["options"] != "undefined" &&
            typeof fieldInfo.type == "undefined"
          ) {
            fieldInfo.type = "select";
          }
        }
        if (fieldInfo.type == "line" || fieldInfo.type == "switchGroup") {
          //console.log(fieldInfo.type);
          fieldInfo.fields = this.buildFieldsValues(
            fieldInfo.fields,
            valueLine
          );
        } else {
          // //console.log(value, " is not a string ");
          if (
            typeof valueLine != "undefined" &&
            typeof valueLine[key] != "undefined"
          ) {
            fieldInfo.value = valueLine[key];
          } else if (typeof fieldInfo.default != "undefined") {
            console.log(fieldInfo.default);
            this.$set(fieldInfo, "value", fieldInfo.default);
            this.$set(valueLine, key, fieldInfo.default);
            this.$emit("input", valueLine);
          }
          fields[key] = fieldInfo;
          //console.log(key, fieldInfo);
          // this.$set(this.valueLine, key, fields[key].value);
        }
      }
      return fields;
    },
  },
};
</script>