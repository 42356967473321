var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('associatedOption',{attrs:{"fieldAttributes":{
      name: 'Type',
      associatedOption: [
        ['array', 'Array'],
        ['object', 'Object'],
      ],
    },"type":"select","fieldAttrInput":{ class: 'required', id: 'httpBodyJSONType' },"templateContent":_vm.result},model:{value:(_vm.rootType),callback:function ($$v) {_vm.rootType=$$v},expression:"rootType"}}),(
      typeof _vm.localValue.content != 'undefined' &&
      Object.keys(_vm.localValue.content).length > 0
    )?_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-subtitle-1"},[_vm._v(" Attribute ")]),_c('v-col',{staticClass:"mt-2",staticStyle:{"max-width":"40px","min-width":"40px"}}),_c('v-col',{staticClass:"text-subtitle-1"},[_vm._v(" Type ")]),_c('v-col',{staticClass:"ml-2 text-subtitle-1"},[_vm._v(" Content ")]),_c('v-col',{staticClass:"ml-2 mt-2",staticStyle:{"max-width":"40px"}})],1):_vm._e(),_c('v-form',{ref:"addingLineForm",attrs:{"lazy-validation":true},model:{value:(_vm.addingFormValid),callback:function ($$v) {_vm.addingFormValid=$$v},expression:"addingFormValid"}},[_c('jsonContentRecursive',{attrs:{"result":_vm.result,"treeLength":1},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}}),_c('primaryButton',{staticClass:"mr-2 mt-3",attrs:{"label":"Add Attribute","icon":"mdi-plus","type":"button","color":"primary","attributesArray":{ id: 'httpBodyJSON_mainAdder' }},on:{"click":function($event){return _vm.addLine()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }