<template>
  <div>
    <slot :localData="localData" @local-data-changed="changeLocalData"></slot>
  </div>
</template>
<script>
import { getSubType, getPageCat } from "@/js/testHelpers.js";
export default {
  props: [
    "stepStoredValue",
    "ipcanStepTemplates",
    "result",
    "application",
    "stepType",
  ],
  data() {
    return { localData: null };
  },
  created() {
    //  this.calculateIndexedTemplates();

    this.setConnectionsValues();
  },
  watch: {
    "stepStoredValue.templateId": function (newVal) {
      console.log("changed templateId", newVal);
      this.getIpcanStepTemplates();
    },
    "stepStoredValue.connectionId": function (newVal) {
      console.log("changed connection", newVal);
      this.getIpcanStepTemplates();
    },
  },
  methods: {
    changeLocalData(localData) {
      console.log("localData", localData);
    },
    setConnectionsValues() {
      let ipcanStep = this.application;
      let stepValue = this.stepStoredValue;

      let subType = "";
      if (
        typeof stepValue != "undefined" &&
        typeof stepValue.subType != "undefined"
      ) {
        subType = stepValue.subType;
      }

      if (
        typeof stepValue != "undefined" &&
        Object.keys(stepValue).length > 0
      ) {
        var indexString =
          '"' +
          ipcanStep +
          ":" +
          stepValue.connectionId +
          ":" +
          stepValue.templateId +
          ":" +
          subType +
          '"';
        if (typeof this.ipcanStepTemplates[indexString] != "undefined") {
          this.localData = this.ipcanStepTemplates[indexString];
          this.$emit("local-data-changed", this.localData);
        }
      }
      if (this.localData == null) {
        this.getIpcanStepTemplates();
      }
    },
    async getIpcanStepTemplates() {
      //let ipcanStep = this.stepType;
      // let stepId = this.stepID;
      let stepType = this.stepType;
      let application = this.application;
      let stepStoredValue = this.stepStoredValue;
      let subType = getSubType(stepType, this.stepStoredValue);

      let category = getPageCat(this.stepStoredValue);
      // let category = "";
      // if (typeof stepStoredValue.category != "undefined") {
      //   category = stepStoredValue.category;
      // }
      // let subType = "";
      // if (typeof stepStoredValue.subType != "undefined") {
      //   subType = stepStoredValue.subType;
      // }
      let systemIdValue = "";
      if (typeof this.result.fields.n_systemId != "undefined") {
        systemIdValue = this.result.valueLine.n_systemId;
      }
      let caller = this;
      this.localData = await getIpcanStepTemplates(
        caller,
        stepType,
        stepStoredValue,
        category,
        subType,
        systemIdValue,
        application
      );
      this.$emit("local-data-changed", this.localData);
    },
  },
};

function getIpcanStepTemplates(
  caller,
  stepType,
  stepStoredValue,
  category,
  subType,
  systemIdValue,
  application
) {
  return new Promise((resolve) => {
    var value = {};
    value["application"] = application;

    // let realStepType = $step.attr("data-application-type");
    value["stepType"] = stepType;
    var callbackArgs = {};

    var templateId = "";

    callbackArgs["selectedFields"] = {};

    callbackArgs["selectedFields"]["packet"] = templateId;
    callbackArgs["selectedFields"]["connection"] = "";
    // callbackArgs["ipcanStep"] = stepType;

    //callbackArgs["stepId"] = stepID;
    callbackArgs["selectedFields"]["connection"] = stepStoredValue.connectionId;
    // callbackArgs['selectedFields']['client'] = stepValue.localClientId;
    // callbackArgs['selectedFields']['server'] = stepValue.remoteServerId;
    callbackArgs["selectedFields"]["packet"] = stepStoredValue.templateId;

    value["selectedFields"] = callbackArgs["selectedFields"];

    let options = {
      function: "getIpcanStepTemplates",
      value: value,
      category: category,
      c_subType: subType,
      n_systemId: systemIdValue,

      requestType: "ajax",
    };

    caller.frameworkAxiosRequest({
      method: 'post',
      url: 'serve.php?f=testing&f2=configureAndRunTests',
      data: options,
    })
        .then(function (response) {
        // console.log(response.data.result.json);
        // setSessionCallback($templatesTable, callbackArgs, localData, "");
        // caller.localData = response.data.result.json;
        resolve(response.data.result.json);
      })
      .catch(function (error) {
        //handle error
        console.log(error);
      });
  });
  // }
}
</script>