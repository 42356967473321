//import $ from 'jquery';
//window.$ = $.extend(require('jquery-ui'));
import {
  isObjectArray
} from "@/js/helper.js";
//import {  } from "@/js/helper.js";
//import { getMandatory, getSupressContent } from "@/js/testStepsHelpers/commonTestFunctions.js";
import Vue from 'vue'
import {
  applyPatch,
  addEditedRequestValues
} from "@/js/testStepsHelpers/commonTestFunctions.js";

function buildHTTPStoredValuesTemplate(template, stepStoredValue, stepType) {
  //var clonedtemplate = Object.assign({}, template);
  var clonedtemplate = JSON.parse(JSON.stringify(template));


  //console.log(clonedtemplate, template);
  if (typeof stepStoredValue != "undefined" && typeof stepStoredValue.parameters != "undefined") {
    // var parameters = stepStoredValue.parameters;
    // if (typeof parameters.connectionId!="undefined" && parameters.connectionId!=""){
    // $additionalFields.find('.connectionSelector').val(parameters.connectionId);
    // }

    // $additionalFields.find('.packetSelector').val(templateId);

    if (typeof clonedtemplate.req == "undefined") {
      // clonedtemplate.req = {};
      Vue.set(clonedtemplate, "req", {});
    }
    if (typeof clonedtemplate["parameters"] != "undefined" && typeof clonedtemplate["parameters"]["content-type"] != "undefined") {
      if (clonedtemplate["parameters"]["content-type"] == "JSON") {
        if (isObjectArray(stepStoredValue.req) && typeof stepStoredValue.req.body == "object") {
          //  console.log(stepStoredValue.req.body);
          if (typeof clonedtemplate.req.body == "undefined") {
            // clonedtemplate.req.body = {};
            Vue.set(clonedtemplate.req, "body", {});
          }
          //  $.each(stepStoredValue.req.body.content,function(innerReqIndex, innerReqValue){
          /*  console.log(JSON.stringify(clonedtemplate.req.body.content));
              console.log(JSON.stringify(stepStoredValue.req.body.content));
              clonedtemplate.req.body.content=addEditedHTTPJSONRequestValues(clonedtemplate.req.body.content, stepStoredValue.req.body.content ,0);
           // });*/
          // console.log(clonedtemplate.req.body, stepStoredValue.req.body);
          let patch = stepStoredValue.req.body;
          let document = clonedtemplate.req.body;


          //clonedtemplate.req.body = applyPatch(document, patch);
          Vue.set(clonedtemplate.req, "body", applyPatch(document, patch));
        }
      } else if (clonedtemplate["parameters"]["content-type"] == "multipart") {
        if (isObjectArray(stepStoredValue.req) && typeof stepStoredValue.req.body == "object") {
          //  console.log(stepStoredValue.req.body);
          if (typeof clonedtemplate.req.body == "undefined") {
            //clonedtemplate.req.body = {};
            Vue.set(clonedtemplate.req, "body", []);
          }

          let originalContent = clonedtemplate.req.body;
          let originalOrder = Array.from(originalContent, x => x.atRanId);
          //console.log(originalContent,originalOrder);
          let storedBody = stepStoredValue.req.body;
          let body = [];

          let i = 0;
          if (storedBody.length > 0) {
            storedBody.forEach(function (value) {

              let index = originalOrder.indexOf(value.atRanId);
              let originalPart = {};
              if (index > -1) {
                originalPart = originalContent[index];
              }

              let patchedValue = originalPart;
              if (typeof value.patch != "undefined") {
                patchedValue = applyPatch(originalPart, value.patch);
              }
              if (index > -1) {
                //body[index] = patchedValue;
                Vue.set(body, index, patchedValue);
              } else {
                //body[originalContent.length + i] = patchedValue;
                Vue.set(body, originalContent.length + i, patchedValue);
                i++;
              }

            });

            //clonedtemplate.req.body = body.filter(value => Object.keys(value).length !== 0);
            Vue.set(clonedtemplate.req, "body", body.filter(value => Object.keys(value).length !== 0));
          }



        }
      } else if (clonedtemplate["parameters"]["content-type"] == "AVP") {
        //clonedtemplate.req.body = addEditedRequestValues(clonedtemplate.req.body, stepStoredValue.req.body, 0);
        Vue.set(clonedtemplate.req, "body", addEditedRequestValues(stepType, clonedtemplate.req.body, stepStoredValue.req.body, 0));
      } else if (clonedtemplate["parameters"]["content-type"] == "text") {
        //clonedtemplate.req.body = addEditedRequestValues(clonedtemplate.req.body, stepStoredValue.req.body, 0);
        if (typeof stepStoredValue.req.body == "string") {

          Vue.set(clonedtemplate.req, "body", stepStoredValue.req.body);
        }
      }
    }
    //console.log(clonedtemplate.req.body.content);
    //clonedtemplate.req.parameters = addEditedRequestValues(clonedtemplate.req.parameters, stepStoredValue.req.parameters, 0);
    Vue.set(clonedtemplate.req, "parameters", addEditedRequestValues(stepType, clonedtemplate.req.parameters, stepStoredValue.req.parameters, 0));
    //  triggerloadHttpFunctions($httpTemplateRow.find('select[name="server"]'),stepStoredValue);
  } else {
    // for ticket DEG-332. using the environment configured value
    //   var  testEnviromentObject =$fieldsObject.data("testEnviromentObject");
    //  switchEnviroment($application,testEnviromentObject);
  }
  return clonedtemplate;
}




// function parseHTTPRequest($attributesContainer) {
//   let $BodyContainer = $attributesContainer.find(".BodyContainer");
//   let $bodyUserInputAccordion = $BodyContainer.find('.userInputAccordion');
//   let $bodyExpandableAccordion = $BodyContainer.find('.expandableAccordion');

//   let $httpContentTypeSelector = $attributesContainer.find(".requestTabsContainer > .tabContainer > .BodyContainer > .attributesTbodyContainer > .httpContentTypeContainer").find(".httpContentTypeSelector");
//   if ($httpContentTypeSelector.length == 0) {
//     $httpContentTypeSelector = $attributesContainer.find(".httpContentTypeContainer").find(".httpContentTypeSelector");
//   }
//   let httpContentType = $httpContentTypeSelector.val();
//   let request;
//   if (httpContentType == "JSON") {
//     let $attributesTbodyContainer = $BodyContainer.find(".attributesTbodyContainer");
//     let originalTemplate = $attributesTbodyContainer.data("originalTemplate");
//     request = {};
//     let type = $bodyExpandableAccordion.find(".mainJsonTypeSelector").val();
//     request["type"] = type;
//     // content could be array or an object

//     request["content"] = {};
//     if (type == "array") {
//       request["content"] = [];
//     }
//     //console.log("grouped diameter",that);
//     /*$bodyUserInputAccordion.find('.jsonBody').children(".jsonAttributeLine").each(function() {
//       var $jsonAttributeLine =$(this);
//       parseHTTPSingleAttributeRow($jsonAttributeLine ,false,request["content"],type);
//     });  
//     $bodyExpandableAccordion.find('.jsonBody').children(".jsonAttributeLine").each(function() {
//       var $jsonAttributeLine =$(this);
//       parseHTTPSingleAttributeRow($jsonAttributeLine ,false,request["content"],type);
//     }); */
//     let parentType = $attributesTbodyContainer.find('.httpContentContainer').children(".jsonAttributesContainer").find(".jsonAttributeTypeSelector").val();

//     let $jsonBody = $attributesTbodyContainer.find('.jsonBody');
//     let testContent = {};
//     testContent["content"]=parseHttpAttributesGroup($jsonBody,parentType);
//     testContent["type"]=parentType;
//     // after parsing all the trees . all the parents has to be initiated
//     //intiateHTTPParents($bodyUserInputAccordion.find('.jsonBody'),request,true);
//     //intiateHTTPParents($bodyExpandableAccordion.find('.jsonBody'),request,true);

//     //let originalTemplate = $jsonBody.data("originalTemplate");
//     let originalContent = originalTemplate.req.body;
//     if (typeof originalContent== "undefined") {
//       originalContent = {};
//       if (parentType == "array") {
//         originalContent = [];
//       }
//     }
//     request = jsonpatch.compare(originalContent, testContent);
//   } else if (httpContentType == "multipart") {
//     let $attributesTbodyContainer = $BodyContainer.find(".attributesTbodyContainer");
//     let originalTemplate = $attributesTbodyContainer.data("originalTemplate");
//     let originalContent = originalTemplate.req.body;
//     let originalOrder = Array.from(originalContent, x => x.atRanId);
//     //console.log(originalContent,originalOrder);
//     request = [];
//     let tmp = [];
//     let $multipartWrapper = $attributesTbodyContainer.find(".httpContentContainer").find(".multipartWrapper");
//     let i = 0;
//     $multipartWrapper.find(".httpSinglePartWrapper").each(function () {
//       let $httpSinglePartWrapper = $(this);
//       let part = parseSinglePart($httpSinglePartWrapper);
//       let index = originalOrder.indexOf(part.atRanId);

//       if (index > -1) {
//         tmp[index] = part;
//       } else {
//         tmp[originalContent.length + i] = part;
//         i++;
//       }

//     });
//     i = 0;
//     for (i = 0; i < tmp.length; i++) {
//       let value = tmp[i];
//       let atRanId;
//       let originalPart = {};
//       if (typeof originalContent[i]!="undefined") {
//         originalPart = originalContent[i];
//         atRanId = originalPart.atRanId;
//       }
//       if (typeof value == "undefined") {
//         value={};
//       }
//        else {
//         atRanId = value.atRanId;
//       } 
//       let patch = jsonpatch.compare(originalPart, value);
//       request[i] = {
//         "atRanId": atRanId,
//         "patch": patch
//       };
//     }
//     request = request.filter(Boolean);
//   } else if (httpContentType == "AVP") {

//     let $parameterAttrTable = $bodyUserInputAccordion.find(".httpContentContainer").find(".parameterAttrTable");
//     let $expandableParameterAttrTable = $bodyExpandableAccordion.find(".httpContentContainer").find(".parameterAttrTable");
//     //  request= getParameters( $parameterAttrTable);

//     request = [];
//     request = getTestParameters($parameterAttrTable, request);
//     request = getTestParameters($expandableParameterAttrTable, request);

//   } else if (httpContentType == "text") {
//     request = $bodyUserInputAccordion.find(".httpContentTypeTextArea").val();
//   }
//   return request;

// }

























export {
  buildHTTPStoredValuesTemplate
};