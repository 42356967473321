<template>
  <div>
    <fieldsRows
      :templateContent="templateContent"
      @field-value-changed="updateFieldValue(...arguments)"
      @updated-fields="emitUpdateFields"
      :valueLine="{
        templateId: this.templateValue,
      }"
    ></fieldsRows>
  </div>
</template>
<script>
import fieldsRows from "@/commonComponents/fieldsRows.vue";
export default {
  components: {
    fieldsRows,
  },
  props: {
    connectionOption: {},
    templateOption: {},
    connectionValue: { type: [String,Number] },
    templateValue: { type: [String,Number] },
    addUrlAttr: { type: Object },
    localData: { type: Object },
    dialogRef: { type: String },
    result: {},
  },
  methods: {
    emitUpdateFields(info) {
      console.log("updated-fields emmited", info);
      for (let [field, line] of Object.entries(info)) {
        this.$set(this.localData, field, line.associatedOption);
        this.updateFieldValue(field, line.value);
      }

      this.$emit("local-data-changed", this.localData);
    },
    updateFieldValue(field, value) {
      console.log(value);
      this.$emit("value-changed", field, value);
    },
  },
  computed: {
    templateContent() {
      let templateContent = {
        fields: {
          // connectionId: {
          //   type: "combobox",
          //   fieldAttrInput: { class: "required" },
          //   value: this.connectionValue,
          //   associatedOption: this.connectionOption,
          //   addingURL:
          //     "serve.php?f=configuration&f2=inboundConnections&f3=inboundConnectionConfiguration",
          //   addingFunction: "addConnection",
          // },

          templateId: {
            type: "combobox",
            value: this.templateValue,
            associatedOption: this.templateOption,
            fieldAttrInput: { class: "required" },
            addingURL:
              "serve.php?f=configuration&f2=inboundPacketTemplates" +
              "&p=" +
              this.addUrlAttr.category +
              "&c_subType=" +
              this.addUrlAttr.subType +
              "&c_type=" +
              this.addUrlAttr.stepType,
          },
        },
        subType: this.addUrlAttr.subType,
        category: this.addUrlAttr.category,
        stepType: this.addUrlAttr.stepType,
        dialogRef: this.dialogRef,
        enableAdd: true,
      };
      if (this.connectionOption === false) {
        this.$delete(templateContent.fields, "connectionId");
      }
      templateContent.fields = this.languageHelpProvider(
        templateContent.fields,
        "connectionBlock"
      );
      templateContent.disableAllInputs = this.result.disableAllInputs;
      return templateContent;
    },
  },
};
</script>
