<template>
  <div class="selecterTypeContainer">
    <v-row class="stepTypeWrapper" no-gutters>
      <v-col style="max-width: 400px" class="inlineDiv typeSelectorWrapper">
        <slot></slot>
      </v-col>
      <v-col class="conditionButtonWrapper ml-4 d-flex" style="max-width: fit-content;">
        <v-switch
          v-model="enableCondition"
          label="Condition"
          :disabled="disabled"
          class="mt-1"
        ></v-switch>
        <HelpButton id="step_condition_switch"></HelpButton>
      </v-col>
    </v-row>

    <v-row class="testConditionLine" v-if="enableCondition">
      <v-col>
        <div class="dot"></div>
        <primaryTextfield
          :fieldAttrInput="{
            class: 'testConditionInput required',id:'step_conditions_input'
          }"
          style="max-width: 536px"
          label="Condition"
          v-model="condition"
        ></primaryTextfield>
        <div class="hintMessage mb-3">
          {{ language.componentLabel.test_conditions_hint }}
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </div>
</template>
<script>
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import HelpButton from "@/components/basic/HelpButton.vue";
export default {
  components: {
    HelpButton,
    primaryTextfield,
  },
  data: function () {
    return {
      enableCondition: false,
    };
  },
  created() {
    this.enableCondition = this.condition.length > 0;

    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "stepResorted") {
         this.enableCondition = this.condition.length > 0;

      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  computed: {
    disabled() {
      let disabled = this.condition.length > 0;

      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },

    condition: {
      get() {
        let condition = "";
        if (typeof this.value.Condition != "undefined") {
          condition = this.value.Condition;
        }
        return condition;
      },
      set(setValue) {
        this.$set(this.value, "Condition", setValue);
        this.$emit("input", this.value);
      },
    },
  },

  props: ["value", "result"],
  methods: {
    toggleTestCondition: function (enabled) {
      this.enableCondition = enabled;
    },
  },
};
</script>